import { GET_TEMPLATE_GUBUN_LIST, GET_TEMPLATE_LIST } from "./action";
import { TEMPALTE_GUBUN_MUTATION } from "./mutation";
import ApiService from "@/services/ApiService";
import apiPath from "@/services/apiPath";

export default {
  state: {
    statusList: [],
  },
  actions: {
    async [GET_TEMPLATE_GUBUN_LIST](context) {
      if (context.state.statusList.length > 0) return; // 한번만 호출.

      const result = await ApiService.shared.getData(apiPath.TEMPLATE_GUBUN);

      this.commit({
        type: TEMPALTE_GUBUN_MUTATION,
        items: result.data || [],
      });
    },
    // async [GET_TEMPLATE_LIST] ( context ) {
    //     await context.dispatch( GET_TEMPLATE_GUBUN_LIST );
    // }
  },
  mutations: {
    [TEMPALTE_GUBUN_MUTATION](state, payload) {
      const arr = payload.items;

      const gubunArray = [];

      arr.forEach((element) => {
        const obj = {
          code: element,
          text: element,
          desc: element,
        };
        gubunArray.push(obj);
        // element.code = element.code;
        // element.text = element.desc;
      });
      gubunArray.unshift({
        code: "",
        text: "전체",
        desc: "전체",
      });

      state.statusList = gubunArray;
    },
  },
};
