import menuId from "@/constants/menuId";
import paymentRouterPath from "./paymentRouterPath.js";
import sideMenuId from "@/constants/admin/sideMenus/sideMenuId.js";

const paymentRouter = [
  {
    path: paymentRouterPath.PAYMENT, // 전자결제
    meta: {
      menuId: menuId.PAYMENT,
    },
    component: () => import("@/_approval/components/common/CommLayout.vue"),
    redirect: paymentRouterPath.PAYMENT_DRAFT_ING,
    children: [
      {
        // 전자결제 > 구매요청서 등록
        path: paymentRouterPath.PAYMENT_WRITE,
        meta: {
          menuId: menuId.PAYMENT,
        },
        component: () => import("@/_approval/pages/payment/write/DraftWrite.vue"),
      },
      {
        // 전자결제 >  내  문서함 > 임시저장문서 - 리스트
        path: paymentRouterPath.PAYMENT_DRAFT_TEMP,
        meta: {
          menuId: menuId.PAYMENT,
        },
        component: () => import("@/_approval/pages/payment/list/draft/DraftTempDocs"),
      },
      {
        // 전자결제 > 구매요청서 수정
        path: paymentRouterPath.PAYMENT_MODIFY + "/:id",
        meta: {
          menuId: menuId.PAYMENT,
          isModifyMode: true,
        },
        component: () => import("@/_approval/pages/payment/write/DraftWrite"),
      },
      {
        // 전자결제 > view 모드(admin, super)
        path: paymentRouterPath.PAYMENT_VIEW_DETAIL + "/:id",
        name: "DraftViewDetail",
        meta: {
          menuId: menuId.PAYMENT,
        },
        component: () => import("@/_approval/pages/payment/view/draft/DraftViewDetail"),
      },
      {
        // 전자결제 >  내  문서함 > 승인한 문서함 - 리스트
        path: paymentRouterPath.PAYMENT_DRAFT_ING,
        meta: {
          menuId: menuId.PAYMENT,
        },
        component: () => import("@/_approval/pages/payment/list/draft/DraftIngDocs"),
      },
      {
        // 전자결제 >  내  문서함 > 완료된 문서 - 리스트
        path: paymentRouterPath.PAYMENT_DRAFT_COMPLETE,
        meta: {
          menuId: menuId.PAYMENT,
        },
        component: () => import("@/_approval/pages/payment/list/draft/DraftCompleteDocs"),
      },
      {
        // 전자결제 >  내  문서함 > 반려된 문서 - 리스트
        path: paymentRouterPath.PAYMENT_DRAFT_REJECT,
        meta: {
          menuId: menuId.PAYMENT,
        },
        component: () => import("@/_approval/pages/payment/list/draft/DraftRejectDocs"),
      },
      {
        // 내 결제함 > 결재전 문서 - 리스트
        path: paymentRouterPath.PAYMENT_APPROVAL_BEFORE,
        meta: {
          menuId: menuId.PAYMENT,
        },
        component: () => import("@/_approval/pages/payment/list/appr/ApprBeforeDocs"),
      },
      {
        // 내 결제함 > 승인할 문서함 - 리스트
        path: paymentRouterPath.PAYMENT_APPROVAL_RECEIVE,
        meta: {
          menuId: menuId.PAYMENT,
        },
        component: () => import("@/_approval/pages/payment/list/appr/ApprReceiveDocs"),
      },
      {
        // 내 결제함 > 승인한 문서함 - 리스트
        path: paymentRouterPath.PAYMENT_APPROVAL_ING,
        meta: {
          menuId: menuId.PAYMENT,
        },
        component: () => import("@/_approval/pages/payment/list/appr/ApprIngDocs"),
      },
      {
        // 내 결제함 > 완료된 문서 - 리스트
        path: paymentRouterPath.PAYMENT_APPROVAL_COMPLETE,
        meta: {
          menuId: menuId.PAYMENT,
        },
        component: () => import("@/_approval/pages/payment/list/appr/ApprCompleteDocs"),
      },
      {
        // 내 결제함 > 반려한 문서 - 리스트
        path: paymentRouterPath.PAYMENT_APPROVAL_REJECT,
        meta: {
          menuId: menuId.PAYMENT,
        },
        component: () => import("@/_approval/pages/payment/list/appr/ApprRejectDocs"),
      },
      {
        // 참조 > 승인한 문서함 - 리스트
        path: paymentRouterPath.PAYMENT_CC_ING,
        meta: {
          menuId: menuId.PAYMENT,
        },
        component: () => import("@/_approval/pages/payment/list/cc/CcIngDocs"),
      },
      {
        // 참조 > 처리된 문서 - 리스트
        path: paymentRouterPath.PAYMENT_CC_COMPLETE,
        meta: {
          menuId: menuId.PAYMENT,
        },
        component: () => import("@/_approval/pages/payment/list/cc/CcCompleteDocs"),
      },
      {
        // 전자결제 >  내  문서함 > 임시저장문서 - 상세
        path: `${paymentRouterPath.PAYMENT_DRAFT_TEMP}/:id`,
        meta: {
          menuId: menuId.PAYMENT,
        },
        component: () => import("@/_approval/pages/payment/view/draft/DraftIngDetail"),
      },
      {
        // 전자결제 >  내  문서함 > 진행중 문서함 - 상세
        path: `${paymentRouterPath.PAYMENT_DRAFT_ING_DETAIL}/:id`,
        meta: {
          menuId: menuId.PAYMENT,
          sideMenuId: sideMenuId.DRAFT_ING,
        },
        component: () => import("@/_approval/pages/payment/view/draft/DraftViewDetail"),
        // component: () => import('@/_approval/pages/doc/view/DraftIngDetail'),
      },
      {
        // 전자결제 >  내  문서함 > 완료된 문서 - 상세
        path: paymentRouterPath.PAYMENT_DRAFT_COMPLETE_DETAIL + "/:id",
        name: "DraftCompleteDetail",
        meta: {
          menuId: menuId.PAYMENT,
          sideMenuId: sideMenuId.DRAFT_COMPLETE,
        },
        component: () => import("@/_approval/pages/payment/view/draft/DraftCompleteDetail"),
      },
      {
        // 전자결제 >  내  문서함 > 반려된 문서 - 상세
        path: paymentRouterPath.PAYMENT_DRAFT_REJECT_DETAIL + "/:id",
        name: "DraftRejectDetail",
        meta: {
          menuId: menuId.PAYMENT,
          sideMenuId: sideMenuId.DRAFT_REJECT,
        },
        component: () => import("@/_approval/pages/payment/view/draft/DraftRejectDetail"),
      },
      {
        // 전자결제 >  내  문서함 > 반려된 문서 - 상세
        path: paymentRouterPath.PAYMENT_DRAFT_REJECT_EDIT,
        name: "DraftRejectEdit",
        meta: {
          menuId: menuId.PAYMENT,
        },
        component: () => import("@/_approval/pages/payment/write/DraftRejectEdit"),
      },
      {
        // 전자결제 > 내 결제함 > 결재전 문서 - 상세
        //path: 'apprBeforeDetail',
        path: paymentRouterPath.PAYMENT_APPROVAL_BEFORE_DETAIL + "/:id",
        name: "ApprBeforeDetail",
        meta: {
          menuId: menuId.PAYMENT,
          sideMenuId: sideMenuId.APPROVAL_BEFORE,
        },
        component: () => import("@/_approval/pages/payment/view/appr/ApprBeforeDetail"),
      },
      {
        // 전자결제 > 내 결제함 > 승인할 문서함 - 상세
        //path: 'apprReceiveDetail',
        path: paymentRouterPath.PAYMENT_APPROVAL_RECEIVE_DETAIL + "/:id",
        name: "ApprReceiveDetail",
        meta: {
          menuId: menuId.PAYMENT,
          sideMenuId: sideMenuId.APPROVAL_RECEIVE,
        },
        component: () => import("@/_approval/pages/payment/view/appr/ApprReceiveDetail"),
      },
      {
        // 전자결제 > 내 결제함 > 승인한 문서함 - 상세
        //path: 'apprIngDetail',
        path: paymentRouterPath.PAYMENT_APPROVAL_ING_DETAIL + "/:id",
        name: "ApprIngDetail",
        meta: {
          menuId: menuId.PAYMENT,
          sideMenuId: sideMenuId.APPROVAL_ING,
        },
        component: () => import("@/_approval/pages/payment/view/appr/ApprIngDetail"),
      },
      {
        // 전자결제 > 내 결제함 > 완료된 문서 - 상세
        //path: 'apprCompleteDetail',
        path: paymentRouterPath.PAYMENT_APPROVAL_COMPLETE_DETAIL + "/:id",
        name: "ApprCompleteDetail",
        meta: {
          menuId: menuId.PAYMENT,
          sideMenuId: sideMenuId.APPROVAL_COMPLETE,
        },
        component: () => import("@/_approval/pages/payment/view/appr/ApprCompleteDetail"),
      },
      {
        // 전자결제 > 내 결제함 > 반려한 문서 - 상세
        //path: 'apprRejectDetail',
        path: paymentRouterPath.PAYMENT_APPROVAL_REJECT_DETAIL + "/:id",
        name: "ApprRejectDetail",
        meta: {
          menuId: menuId.PAYMENT,
          sideMenuId: sideMenuId.APPROVAL_REJECT,
        },
        component: () => import("@/_approval/pages/payment/view/appr/ApprRejectDetail"),
      },
      {
        // 전자결제 > 참조 > 승인한 문서함 - 상세
        //path: 'ccIngDetail',
        path: paymentRouterPath.PAYMENT_CC_ING_DETAIL + "/:id",
        name: "CcIngDetail",
        meta: {
          menuId: menuId.PAYMENT,
          sideMenuId: sideMenuId.CC_ING,
        },
        component: () => import("@/_approval/pages/payment/view/cc/CcIngDetail"),
      },
      {
        // 전자결제 > 참조 > 완료된 문서 - 상세
        //path: 'ccCompleteDetail',
        path: paymentRouterPath.PAYMENT_CC_COMPLETE_DETAIL + "/:id",
        name: "CcCompleteDetail",
        meta: {
          menuId: menuId.PAYMENT,
          sideMenuId: sideMenuId.CC_COMPLETE,
        },
        component: () => import("@/_approval/pages/payment/view/cc/CcCompleteDetail"),
      },
    ],
  },
  {
    path: paymentRouterPath.PAYMENT_SYSTEM, // 전자결제 시스템
    meta: {
      menuId: menuId.PAYMENT_SYSTEM,
    },
    component: () => import("@/_approval/components/common/CommLayout.vue"),
    redirect: paymentRouterPath.PAYMENT_SYSTEM_PUBLIC_LINE_LIST,
    children: [
      {
        // 공용결재선 관리
        path: paymentRouterPath.PAYMENT_SYSTEM_PUBLIC_LINE_LIST,

        meta: {
          menuId: menuId.PAYMENT_SYSTEM,
        },
        component: () => import("@/_approval/pages/admin/publicApproval/PublicApprovalLine.vue"),
      },
      {
        // 공용결재선 관리 - 등록, 수정
        path: paymentRouterPath.PAYMENT_SYSTEM_PUBLIC_LINE_WRITE,
        name: paymentRouterPath.PAYMENT_SYSTEM_PUBLIC_LINE_WRITE,
        meta: {
          menuId: menuId.PAYMENT_SYSTEM,
        },
        component: () =>
          import("@/_approval/pages/admin/publicApproval/PublicApprovalLineEdit.vue"),
      },
      {
        // 결제선 관리
        path: paymentRouterPath.PAYMENT_SYSTEM_ACCOUNT_LIST,

        meta: {
          menuId: menuId.PAYMENT_SYSTEM,
        },
        component: () => import("@/_approval/pages/admin/payAccount/PayAccountList.vue"),
      },
      {
        // 결제선 관리 - 등록, 수정
        path: paymentRouterPath.PAYMENT_SYSTEM_ACCOUNT_WRITE,

        name: "payAccountEdit",
        meta: {
          menuId: menuId.PAYMENT_SYSTEM,
        },
        component: () => import("@/_approval/pages/admin/payAccount/PayAccountEdit.vue"),
      },
      {
        // 가이드 관리
        path: paymentRouterPath.PAYMENT_SYSTEM_USE_GUIDE_LIST,
        meta: {
          menuId: menuId.PAYMENT_SYSTEM,
        },
        component: () => import("@/_approval/pages/admin/useGuide/UseGuideList.vue"),
      },
      {
        // 가이드 관리 - 수정
        path: paymentRouterPath.PAYMENT_SYSTEM_USE_GUIDE_WRITE,
        name: "useGuideEdit",
        meta: {
          menuId: menuId.PAYMENT_SYSTEM,
        },
        component: () => import("@/_approval/pages/admin/useGuide/UseGuideEdit"),
      },
    ],
  },
];

export default paymentRouter;
