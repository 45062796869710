import { ALERT_ACTION } from "./action";
import { ALERT_MUTATION } from "./mutation";

const { SHOW_ALERT_YN, HIDE_ALERT_YN, SHOW_ALERT, HIDE_ALERT } = ALERT_ACTION;
const { SHOW_ALERT_YN_MUTATION, HIDE_ALERT_YN_MUTATION, SHOW_ALERT_MUTATION, HIDE_ALERT_MUTATION } =
  ALERT_MUTATION;

export default {
  state: {
    isShowAlertYN: false,
    alertText: "",
    buttonTextY: "확인",
    buttonTextN: "취소",
    onClickY: null,
    onClickN: null,

    isShowAlert: false,
  },
  actions: {
    [SHOW_ALERT_YN](context, params) {
      this.commit({
        type: SHOW_ALERT_YN_MUTATION,
        params,
      });
    },
    [HIDE_ALERT_YN](context) {
      this.commit({
        type: HIDE_ALERT_YN_MUTATION,
      });
    },
    [SHOW_ALERT](context, params) {
      this.commit({
        type: SHOW_ALERT_MUTATION,
        params,
      });
    },
    [HIDE_ALERT](context) {
      this.commit({
        type: HIDE_ALERT_MUTATION,
      });
    },
  },
  mutations: {
    [SHOW_ALERT_YN_MUTATION](state, payload) {
      const { params } = payload;

      state.alertText = params.text;

      state.buttonTextY = params.buttonTextY || "확인";
      state.buttonTextN = params.buttonTextN || "취소";

      state.onClickY = () => {
        state.isShowAlertYN = false;

        if (params.onClickY) {
          params.onClickY();
        }
      };

      state.onClickN = () => {
        state.isShowAlertYN = false;

        if (params.onClickN) {
          params.onClickN();
        }
      };

      state.isShowAlertYN = true;
    },
    [HIDE_ALERT_YN_MUTATION](state, payload) {
      state.isShowAlertYN = false;
    },
    [SHOW_ALERT_MUTATION](state, payload) {
      const { params } = payload;

      // // 하다보니까 단순 얼럿을 오브젝트로 보내는 게 귀찮아서, 그런 경우 그냥 문자열만 보내게끔 처리.
      const isOnlyText = !params?.text;
      state.alertText = isOnlyText ? params : params.text;
      // state.alertText = params.text;

      state.buttonTextY = params?.buttonTextY || "확인";

      state.onClickY = () => {
        state.isShowAlert = false;

        if (params?.onClickY) {
          params?.onClickY();
        }
      };
      state.isShowAlert = true;
    },
    [HIDE_ALERT_MUTATION](state, payload) {
      state.isShowAlert = false;
    },
  },
};

export { ALERT_ACTION };
