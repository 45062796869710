import { OPEN_STATEMENT_SIDE_MENU, TOGGLE_STATEMENT_SIDE_MENU } from "./action";
import { STATEMENT_SIDE_MENU_OPEN_MUTATION, STATEMENT_SIDE_MENU_TOGGLE_MUTATION } from "./mutation";
import sideMenuId from "@/constants/sideMenuId";

//신규
import ApiService from "@/services/ApiService";
import apiPath from "@/services/apiPath";

import { GET_STATEMENT_STATUS_LIST, STATEMENT_LIST_ACTION, STATEMENT_WRITE_ACTION } from "./action";
import { STATEMENT_STATUS_LIST_MUTATION } from "./mutation";
import { CATEGORY_ACTION } from "../category/action";

export default {
  state: {
    menuOpenStatus: {
      draft: false,
      approval: false,
      cc: false,
      admin: false,
      statementManagement: false,
    },

    //신규
    statusList: [],
  },
  actions: {
    [OPEN_STATEMENT_SIDE_MENU](context, payload) {
      const { sideMenuId } = payload;

      this.commit({
        type: STATEMENT_SIDE_MENU_OPEN_MUTATION,
        sideMenuId,
      });
    },
    [TOGGLE_STATEMENT_SIDE_MENU](context, payload) {
      const { sideMenuId } = payload;

      this.commit({
        type: STATEMENT_SIDE_MENU_TOGGLE_MUTATION,
        sideMenuId,
      });
    },

    //신규
    async [GET_STATEMENT_STATUS_LIST](context) {
      if (context.state.statusList.length > 0) return; // 한번만 호출.
      //console.error('GET_STATEMENT_STATUS_LIST 변경해야됨');
      //const result = await ApiService.shared.getData(apiPath.GET_STATEMENT_STATUS_LIST);
      const result = {
        data: [
          { id: "0", code: "0", desc: "전체" },
          { id: "Y", code: "Y", desc: "등록" },
          { id: "N", code: "N", desc: "미등록" },
        ],
      };

      this.commit({
        type: STATEMENT_STATUS_LIST_MUTATION,
        items: result.data || [],
      });
    },
    async [STATEMENT_LIST_ACTION](context) {
      await context.dispatch(GET_STATEMENT_STATUS_LIST);

      await context.dispatch(CATEGORY_ACTION.GET_LIST_0);
    },
    async [STATEMENT_WRITE_ACTION](context) {
      await context.dispatch(CATEGORY_ACTION.GET_LIST_0);
    },
  },
  mutations: {
    [STATEMENT_SIDE_MENU_OPEN_MUTATION](state, payload) {
      switch (payload.sideMenuId) {
        case sideMenuId.DRAFT:
          state.menuOpenStatus.draft = true;
          break;
        case sideMenuId.APPROVAL:
          state.menuOpenStatus.approval = true;
          break;
        case sideMenuId.CC:
          state.menuOpenStatus.cc = true;
          break;
        case sideMenuId.MANAGEMENT:
          state.menuOpenStatus.admin = true;
          break;
        case sideMenuId.STATEMENT_MANAGEMENT:
          state.menuOpenStatus.statementManagement = true;
          break;
      }
    },
    [STATEMENT_SIDE_MENU_TOGGLE_MUTATION](state, payload) {
      switch (payload.sideMenuId) {
        case sideMenuId.DRAFT:
          state.menuOpenStatus.draft = !state.menuOpenStatus.draft;
          break;
        case sideMenuId.APPROVAL:
          state.menuOpenStatus.approval = !state.menuOpenStatus.approval;
          break;
        case sideMenuId.CC:
          state.menuOpenStatus.cc = !state.menuOpenStatus.cc;
          break;
        case sideMenuId.MANAGEMENT:
          state.menuOpenStatus.admin = !state.menuOpenStatus.admin;
          break;
        case sideMenuId.STATEMENT_MANAGEMENT:
          state.menuOpenStatus.statementManagement = !state.menuOpenStatus.statementManagement;
          break;
      }
    },

    //신규
    [STATEMENT_STATUS_LIST_MUTATION](state, payload) {
      // code 로 들어오는 값을 기준 값으로 (value)
      const arr = payload.items;

      arr.forEach((item) => {
        item.value = item.code;
      });

      state.statusList = arr;
    },
  },
};
