import ligRouterPath from '@LIG/router/ligRouterPath';
import menuId from '@/constants/menuId';

const menuData = [
  {
    title: '기본정보',
    link: ligRouterPath.ITEM_LIST,
    arrSub: [
      {
        title: '품목관리',
        link: ligRouterPath.ITEM_LIST,
        roleInfos: [
          {
            menuId: menuId.ITEM_READ,
            roleTitle: '조회',
          },
          {
            menuId: menuId.ITEM_WRITE,
            roleTitle: '등록',
          },
        ],
      },
      {
        title: '협력사 관리',
        link: ligRouterPath.PARTNER_LIST,
        roleInfos: [
          {
            menuId: menuId.PARTNER_READ,
            roleTitle: '조회',
          },
          {
            menuId: menuId.PARTNER_WRITE,
            roleTitle: '등록',
          },
        ],
      },
      // {
      //   title: '수행도평가 관리',
      //   link: ligRouterPath.PERFORMANCE_LIST,
      //   roleInfos: [
      //     {
      //       menuId: menuId.PERFORMANCE_READ,
      //       roleTitle: '조회',
      //     },
      //     {
      //       menuId: menuId.PERFORMANCE_WRITE,
      //       roleTitle: '등록',
      //     },
      //   ],
      // },
    ],
  },
  {
    title: '구매관리',
    link: ligRouterPath.PURCHASE_LIST,
    arrSub: [
      {
        title: '구매요청',
        link: ligRouterPath.PURCHASE_LIST,
        roleInfos: [
          {
            menuId: menuId.PURCHASE_READ,
            roleTitle: '조회',
          },
          {
            menuId: menuId.PURCHASE_WRITE,
            roleTitle: '등록',
          },
        ],
      },
      {
        title: '견적관리',
        link: ligRouterPath.ESTIMATE_LIST,
        roleInfos: [
          {
            menuId: menuId.ESTIMATE_READ,
            roleTitle: '조회',
          },
          {
            menuId: menuId.ESTIMATE_WRITE,
            roleTitle: '등록',
          },
        ],
      },
      {
        title: '공고관리',
        link: ligRouterPath.ANNOUNCE_LIST,
        roleInfos: [
          {
            menuId: menuId.ANNOUNCE_READ,
            roleTitle: '조회',
          },
          {
            menuId: menuId.ANNOUNCE_WRITE,
            roleTitle: '등록',
          },
        ],
      },
      {
        title: '입찰관리',
        link: ligRouterPath.BID_LIST,
        roleInfos: [
          {
            menuId: menuId.BID_READ,
            roleTitle: '조회',
          },
          // {
          //   menuId: menuId.BID_WRITE,
          //   roleTitle: '등록'
          // },
        ],
      },
      // {
      //   title: '기안관리',
      //   link: ligRouterPath.DRAFT_LIST,
      //   roleInfos: [
      //     {
      //       menuId: menuId.DRAFT_READ,
      //       roleTitle: '조회',
      //     },
      //     {
      //       menuId: menuId.DRAFT_WRITE,
      //       roleTitle: '등록',
      //     },
      //   ],
      // },
    ],
  },
  {
    title: '계약/발주',
    link: ligRouterPath.CONTRACT_LIST,
    arrSub: [
      {
        title: '계약관리',
        link: ligRouterPath.CONTRACT_LIST,
        roleInfos: [
          {
            menuId: menuId.CONTRACT_READ,
            roleTitle: '조회',
          },
          {
            menuId: menuId.CONTRACT_WRITE,
            roleTitle: '등록',
          },
        ],
      },
      {
        title: '발주관리',
        link: ligRouterPath.ORDER_LIST,
        roleInfos: [
          {
            menuId: menuId.ORDER_READ,
            roleTitle: '조회',
          },
          {
            menuId: menuId.ORDER_WRITE,
            roleTitle: '등록',
          },
        ],
      },
    ],
  },
  {
    title: '마감/정산',
    link: ligRouterPath.INSPECT_LIST,
    arrSub: [
      {
        title: '검수관리',
        link: ligRouterPath.INSPECT_LIST,
        roleInfos: [
          {
            menuId: menuId.INSPECT_READ,
            roleTitle: '조회',
          },
          {
            menuId: menuId.INSPECT_WRITE,
            roleTitle: '등록',
          },
        ],
      },
      {
        title: '세금계산서관리',
        link: ligRouterPath.TRANSACTIONREPORT_LIST,
        roleInfos: [
          {
            menuId: menuId.TRANSACTIONREPORT_READ,
            roleTitle: '조회',
          },
          {
            menuId: menuId.TRANSACTIONREPORT_WRITE,
            roleTitle: '등록',
          },
        ],
      },
      // {
      //   title: '정산관리',
      //   link: ligRouterPath.STATEMENT_LIST,
      //   roleInfos: [
      //     {
      //       menuId: menuId.STATEMENT_READ,
      //       roleTitle: '조회',
      //     },
      //     {
      //       menuId: menuId.STATEMENT_WRITE,
      //       roleTitle: '등록',
      //     },
      //   ],
      // },
    ],
  },
  {
    title: '전자결재',
    link: ligRouterPath.PAYMENT,
    isNotShowSub: true,
    arrSub: [
      {
        title: '전자결재',
        link: ligRouterPath.PAYMENT,
        roleInfos: [
          {
            menuId: menuId.PAYMENT,
            roleTitle: '조회/등록',
          },
          {
            menuId: menuId.PAYMENT_SYSTEM,
            roleTitle: '관리',
          },
        ],
      },
    ],
  },
  {
    title: '통계',
    link: ligRouterPath.STATISTICS,
    arrSub: [
      {
        title: '구매요청현황',
        link: ligRouterPath.STATISTICS_ORDERLIST,
        roleInfos: [
          {
            menuId: menuId.STATISTICS_ORDERLIST,
            roleTitle: '조회',
          },
        ],
      },
      {
        title: '발주건별정보',
        link: ligRouterPath.STATISTICS_ORDERCASE,
        roleInfos: [
          {
            menuId: menuId.STATISTICS_ORDERCASE,
            roleTitle: '조회',
          },
        ],
      },
    ],
  },
  {
    title: '시스템관리',
    link: ligRouterPath.SYSTEM_NOTICE_LIST,
    arrSub: [
      // {
      //   title: '코드관리',
      //   link: ligRouterPath.SYSTEM_CODE,
      //   roleInfos: [
      //     {
      //       menuId: menuId.SYSTEM_CODE_READ,
      //       roleTitle: '조회',
      //     },
      //     {
      //       menuId: menuId.SYSTEM_CODE_WRITE,
      //       roleTitle: '등록',
      //     },
      //   ],
      // },
      {
        title: '취급품목',
        link: ligRouterPath.SYSTEM_HANDLED,
        roleInfos: [
          {
            menuId: menuId.SYSTEM_HANDLED,
            roleTitle: '조회/등록',
          },
        ],
      },
      {
        title: '카테고리',
        link: ligRouterPath.SYSTEM_CATEGORY,
        roleInfos: [
          {
            menuId: menuId.SYSTEM_CATEGORY,
            roleTitle: '조회/등록',
          },
        ],
      },
      {
        title: '템플릿관리',
        link: ligRouterPath.SYSTEM_TEMPLATE_LIST,
        roleInfos: [
          {
            menuId: menuId.SYSTEM_TEMPLATE_READ,
            roleTitle: '조회',
          },
          {
            menuId: menuId.SYSTEM_TEMPLATE_WRITE,
            roleTitle: '등록',
          },
        ],
      },
      {
        title: '업체(평가)관리',
        link: ligRouterPath.SYSTEM_PERFORMANCE_PRESENT,
        roleInfos: [
          {
            menuId: menuId.SYSTEM_PERFORMANCE_READ,
            roleTitle: '조회',
          },
          {
            menuId: menuId.SYSTEM_PERFORMANCE_WRITE,
            roleTitle: '등록',
          },
        ],
      },

      {
        title: '담당자관리',
        link: ligRouterPath.SYSTEM_DIRECTOR_LIST,
        roleInfos: [
          {
            menuId: menuId.SYSTEM_DIRECTOR_READ,
            roleTitle: '조회',
          },
          {
            menuId: menuId.SYSTEM_DIRECTOR_WRITE,
            roleTitle: '등록',
          },
        ],
      },

      {
        title: '공지사항',
        link: ligRouterPath.SYSTEM_NOTICE_LIST,
        roleInfos: [
          {
            menuId: menuId.SYSTEM_NOTICE_READ,
            roleTitle: '조회',
          },
          {
            menuId: menuId.SYSTEM_NOTICE_WRITE,
            roleTitle: '등록',
          },
        ],
      },
    ],
  },
];

export { menuData };
