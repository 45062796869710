import routerPath from "@/router/routerPath";
import menuId from "@/constants/menuId";

const menuData = [
  {
    title: "기본정보",
    link: routerPath.ITEM_LIST,
    arrSub: [
      {
        title: "품목관리",
        link: routerPath.ITEM_LIST,
        roleInfos: [
          {
            menuId: menuId.ITEM_READ,
            roleTitle: "조회",
          },
          {
            menuId: menuId.ITEM_WRITE,
            roleTitle: "등록",
          },
        ],
      },
      {
        title: "협력사 관리",
        link: routerPath.PARTNER_LIST,
        roleInfos: [
          {
            menuId: menuId.PARTNER_READ,
            roleTitle: "조회",
          },
          {
            menuId: menuId.PARTNER_WRITE,
            roleTitle: "등록",
          },
        ],
      },
    ],
  },
  {
    title: "구매관리",
    link: routerPath.PURCHASE_LIST,
    arrSub: [
      {
        title: "구매요청",
        link: routerPath.PURCHASE_LIST,
        roleInfos: [
          {
            menuId: menuId.PURCHASE_READ,
            roleTitle: "조회",
          },
          {
            menuId: menuId.PURCHASE_WRITE,
            roleTitle: "등록",
          },
        ],
      },
      {
        title: "견적관리",
        link: routerPath.ESTIMATE_LIST,
        roleInfos: [
          {
            menuId: menuId.ESTIMATE_READ,
            roleTitle: "조회",
          },
          {
            menuId: menuId.ESTIMATE_WRITE,
            roleTitle: "등록",
          },
        ],
      },
      {
        title: "공고관리",
        link: routerPath.ANNOUNCE_LIST,
        roleInfos: [
          {
            menuId: menuId.ANNOUNCE_READ,
            roleTitle: "조회",
          },
          {
            menuId: menuId.ANNOUNCE_WRITE,
            roleTitle: "등록",
          },
        ],
      },
      {
        title: "입찰관리",
        link: routerPath.BID_LIST,
        roleInfos: [
          {
            menuId: menuId.BID_READ,
            roleTitle: "조회",
          },
        ],
      },
      {
        title: "기안관리",
        link: routerPath.DRAFT_LIST,
        roleInfos: [
          {
            menuId: menuId.DRAFT_READ,
            roleTitle: "조회",
          },
          {
            menuId: menuId.DRAFT_WRITE,
            roleTitle: "등록",
          },
        ],
      },
    ],
  },
  {
    title: "계약/발주",
    link: routerPath.CONTRACT_LIST,
    arrSub: [
      {
        title: "계약관리",
        link: routerPath.CONTRACT_LIST,
        roleInfos: [
          {
            menuId: menuId.CONTRACT_READ,
            roleTitle: "조회",
          },
          {
            menuId: menuId.CONTRACT_WRITE,
            roleTitle: "등록",
          },
        ],
      },
      {
        title: "발주관리",
        link: routerPath.ORDER_LIST,
        roleInfos: [
          {
            menuId: menuId.ORDER_READ,
            roleTitle: "조회",
          },
          {
            menuId: menuId.ORDER_WRITE,
            roleTitle: "등록",
          },
        ],
      },
    ],
  },
  {
    title: "마감/정산",
    link: routerPath.INSPECT_LIST,
    arrSub: [
      {
        title: "검수관리",
        link: routerPath.INSPECT_LIST,
        roleInfos: [
          {
            menuId: menuId.INSPECT_READ,
            roleTitle: "조회",
          },
          {
            menuId: menuId.INSPECT_WRITE,
            roleTitle: "등록",
          },
        ],
      },
      {
        title: "세금계산서관리",
        link: routerPath.TRANSACTIONREPORT_LIST,
        roleInfos: [
          {
            menuId: menuId.TRANSACTIONREPORT_READ,
            roleTitle: "조회",
          },
          {
            menuId: menuId.TRANSACTIONREPORT_WRITE,
            roleTitle: "등록",
          },
        ],
      },
    ],
  },
  {
    title: "전자결재",
    link: routerPath.PAYMENT,
    isNotShowSub: true,
    arrSub: [
      {
        title: "전자결재",
        link: routerPath.PAYMENT,
        roleInfos: [
          {
            menuId: menuId.PAYMENT,
            roleTitle: "조회/등록",
          },
          {
            menuId: menuId.PAYMENT_SYSTEM,
            roleTitle: "관리",
          },
        ],
      },
    ],
  },

  {
    title: "시스템관리",
    link: routerPath.SYSTEM_NOTICE_LIST,
    arrSub: [
      {
        title: "취급품목",
        link: routerPath.SYSTEM_HANDLED,
        roleInfos: [
          {
            menuId: menuId.SYSTEM_HANDLED,
            roleTitle: "조회/등록",
          },
        ],
      },
      {
        title: "카테고리",
        link: routerPath.SYSTEM_CATEGORY,
        roleInfos: [
          {
            menuId: menuId.SYSTEM_CATEGORY,
            roleTitle: "조회/등록",
          },
        ],
      },
      {
        title: "템플릿관리",
        link: routerPath.SYSTEM_TEMPLATE_LIST,
        roleInfos: [
          {
            menuId: menuId.SYSTEM_TEMPLATE_READ,
            roleTitle: "조회",
          },
          {
            menuId: menuId.SYSTEM_TEMPLATE_WRITE,
            roleTitle: "등록",
          },
        ],
      },
      {
        title: "업체(평가)관리",
        link: routerPath.SYSTEM_PERFORMANCE_PRESENT,
        roleInfos: [
          {
            menuId: menuId.SYSTEM_PERFORMANCE_READ,
            roleTitle: "조회",
          },
          {
            menuId: menuId.SYSTEM_PERFORMANCE_WRITE,
            roleTitle: "등록",
          },
        ],
      },
      {
        title: "담당자관리",
        link: routerPath.SYSTEM_DIRECTOR_LIST,
        roleInfos: [
          {
            menuId: menuId.SYSTEM_DIRECTOR_READ,
            roleTitle: "조회",
          },
          {
            menuId: menuId.SYSTEM_DIRECTOR_WRITE,
            roleTitle: "등록",
          },
        ],
      },

      {
        title: "공지사항",
        link: routerPath.SYSTEM_NOTICE_LIST,
        roleInfos: [
          {
            menuId: menuId.SYSTEM_NOTICE_READ,
            roleTitle: "조회",
            isDefault: true,
          },
          {
            menuId: menuId.SYSTEM_NOTICE_WRITE,
            roleTitle: "등록",
          },
        ],
      },
    ],
  },
];

export { menuData };
