import {
  GET_CONTRACT_STATUS_LIST,
  GET_CONTRACT_TYPE_LIST,
  GET_CONTRACT_PART_LIST,
  GET_CONTRACT_REDUCE_LIST,
  GET_CONTRACT_KEYWORD_LIST,
  GET_CONTRACT_CALCULATE_RANGE_LIST,
  GET_CONTRACT_CALCULATE_TYPE_LIST,
  GET_CONTRACT_PARTNER_INSPECT_TYPE_LIST,
  GET_CONTRACT_CONDITION_TYPE_LIST,
  GET_CONTRACT_WRITE,
  GET_CONTRACT_LIST,
  GET_CONTRACT_COMPANY_CODE_LIST,
} from "./action";
import {
  CONTRACT_STATUS_LIST_MUTATION,
  CONTRACT_TYPE_LIST_MUTATION,
  CONTRACT_PART_LIST_MUTATION,
  CONTRACT_REDUCE_LIST_MUTATION,
  CONTRACT_KEYWORD_LIST_MUTATION,
  CONTRACT_CALCULATE_RANGE_LIST_MUTATION,
  CONTRACT_CALCULATE_TYPE_LIST_MUTATION,
  CONTRACT_PARTNER_INSPECT_TYPE_LIST_MUTATION,
  CONTRACT_CONDITION_TYPE_LIST_MUTATION,
  CONTRACT_COMPANY_CODE_LIST_MUTATION,
} from "./mutation";
import ApiService from "@/services/ApiService";
import apiPath from "@/services/apiPath";

import { CATEGORY_ACTION } from "../category/action";

export default {
  state: {
    statusList: [],
    typeList: [],
    keywordList: [],
    partList: [],
    reduceList: [],
    calculateRangeList: [],
    calculateTypeList: [],
    calculateStatusList: [],
    conditionTypeList: [],
    companyCodeList: [],
  },
  actions: {
    async [GET_CONTRACT_COMPANY_CODE_LIST](context) {
      if (context.state.companyCodeList.length > 0) return; // 한번만 호출.

      const result = await ApiService.shared.getData(apiPath.CONTRACT_COMPANY_CODE);

      this.commit({
        type: CONTRACT_COMPANY_CODE_LIST_MUTATION,
        items: result.data || [],
      });
    },
    async [GET_CONTRACT_STATUS_LIST](context) {
      if (context.state.statusList.length > 0) return; // 한번만 호출.

      const result = await ApiService.shared.getData(apiPath.CONTRACT_STATUS_LIST);

      this.commit({
        type: CONTRACT_STATUS_LIST_MUTATION,
        items: result.data || [],
      });
    },
    async [GET_CONTRACT_TYPE_LIST](context) {
      if (context.state.typeList.length > 0) return; // 한번만 호출.

      const result = await ApiService.shared.getData(apiPath.CONTRACT_TYPE_LIST);

      this.commit({
        type: CONTRACT_TYPE_LIST_MUTATION,
        items: result.data || [],
      });
    },
    async [GET_CONTRACT_PART_LIST](context) {
      if (context.state.partList.length > 0) return; // 한번만 호출.

      const result = await ApiService.shared.getData(apiPath.CONTRACT_PART_LIST);

      this.commit({
        type: CONTRACT_PART_LIST_MUTATION,
        items: result.data || [],
      });
    },
    async [GET_CONTRACT_REDUCE_LIST](context) {
      if (context.state.reduceList.length > 0) return; // 한번만 호출.

      const result = await ApiService.shared.getData(apiPath.CONTRACT_REDUCE_LIST);

      this.commit({
        type: CONTRACT_REDUCE_LIST_MUTATION,
        items: result.data || [],
      });
    },
    async [GET_CONTRACT_KEYWORD_LIST](context) {
      if (context.state.keywordList.length > 0) return; // 한번만 호출.

      //const result = await ApiService.shared.getData(apiPath.ITEM_STATUS_LIST); //API가 없어서 front에서 하드코딩함
      const result = {
        code: "",
        data: [
          { id: "projectName", code: "projectName", desc: "프로젝트명" },
          { id: "companyName", code: "companyName", desc: "파트너사" },
          { id: "title", code: "title", desc: "계약명" },
          { id: "contractCid", code: "contractCid", desc: "계약번호" },
          { id: "purchase", code: "purchase", desc: "구매담당자" },
          { id: "inspect", code: "inspect", desc: "검수담당자" },
        ],
        text: "",
      };
      const { code, data, text } = result;
      this.commit({
        type: CONTRACT_KEYWORD_LIST_MUTATION,
        list: data || [],
      });
    },

    async [GET_CONTRACT_CALCULATE_RANGE_LIST](context) {
      if (context.state.calculateRangeList.length > 0) return; // 한번만 호출.

      const result = await ApiService.shared.getData(apiPath.CONTRACT_CALCULATE_RANGE_LIST);

      this.commit({
        type: CONTRACT_CALCULATE_RANGE_LIST_MUTATION,
        items: result.data || [],
      });
    },
    async [GET_CONTRACT_CALCULATE_TYPE_LIST](context) {
      if (context.state.calculateTypeList.length > 0) return; // 한번만 호출.

      const result = await ApiService.shared.getData(apiPath.CONTRACT_CALCULATE_TYPE_LIST);

      this.commit({
        type: CONTRACT_CALCULATE_TYPE_LIST_MUTATION,
        items: result.data || [],
      });
    },
    async [GET_CONTRACT_PARTNER_INSPECT_TYPE_LIST](context) {
      if (context.state.calculateStatusList.length > 0) return; // 한번만 호출.

      const result = await ApiService.shared.getData(apiPath.CONTRACT_PARTNER_INSPECT_TYPE_LIST);

      this.commit({
        type: CONTRACT_PARTNER_INSPECT_TYPE_LIST_MUTATION,
        items: result.data || [],
      });
    },
    async [GET_CONTRACT_CONDITION_TYPE_LIST](context) {
      if (context.state.conditionTypeList.length > 0) return; // 한번만 호출.

      const result = await ApiService.shared.getData(apiPath.CONTRACT_CONDITION_TYPE_LIST);

      this.commit({
        type: CONTRACT_CONDITION_TYPE_LIST_MUTATION,
        items: result.data || [],
      });
    },
    async [GET_CONTRACT_WRITE](context, { needCompanyCode }) {
      await context.dispatch(GET_CONTRACT_STATUS_LIST);

      await context.dispatch(GET_CONTRACT_TYPE_LIST);

      await context.dispatch(GET_CONTRACT_PART_LIST);

      await context.dispatch(GET_CONTRACT_REDUCE_LIST);

      await context.dispatch(GET_CONTRACT_CONDITION_TYPE_LIST);

      await context.dispatch(CATEGORY_ACTION.GET_LIST_0);

      if (needCompanyCode) {
        await context.dispatch(GET_CONTRACT_COMPANY_CODE_LIST);
      }
    },
    async [GET_CONTRACT_LIST](context) {
      await context.dispatch(GET_CONTRACT_CALCULATE_RANGE_LIST);

      await context.dispatch(GET_CONTRACT_CALCULATE_TYPE_LIST);

      await context.dispatch(GET_CONTRACT_PARTNER_INSPECT_TYPE_LIST);

      await context.dispatch(GET_CONTRACT_STATUS_LIST);

      await context.dispatch(GET_CONTRACT_TYPE_LIST);

      await context.dispatch(GET_CONTRACT_KEYWORD_LIST);

      await context.dispatch(CATEGORY_ACTION.GET_LIST_0);
    },
  },
  mutations: {
    [CONTRACT_COMPANY_CODE_LIST_MUTATION](state, payload) {
      state.companyCodeList = payload.items;
    },
    [CONTRACT_STATUS_LIST_MUTATION](state, payload) {
      // code 로 들어오는 값을 기준 값으로 (value)
      const arr = payload.items;

      arr.forEach((item) => {
        item.value = item.code;
      });

      state.statusList = arr;
    },
    [CONTRACT_TYPE_LIST_MUTATION](state, payload) {
      // code 로 들어오는 값을 기준 값으로 (value)
      const arr = payload.items;

      arr.forEach((item) => {
        item.value = item.code;
      });

      state.typeList = arr;
    },
    [CONTRACT_PART_LIST_MUTATION](state, payload) {
      // code 로 들어오는 값을 기준 값으로 (value)
      const arr = payload.items;

      arr.forEach((item) => {
        item.value = item.code;
      });

      state.partList = arr;
    },
    [CONTRACT_REDUCE_LIST_MUTATION](state, payload) {
      // code 로 들어오는 값을 기준 값으로 (value)
      const arr = payload.items;

      arr.push({
        code: "직접입력",
        desc: "직접입력",
      });

      arr.forEach((item) => {
        item.value = item.code;
      });

      state.reduceList = arr;
    },
    [CONTRACT_KEYWORD_LIST_MUTATION](state, payload) {
      //계약관리 검색어
      const { list } = payload;
      state.keywordList = Object.freeze(
        list.map((item, index) => Object.freeze({ ...item, index: index })),
      );
    },
    [CONTRACT_CALCULATE_RANGE_LIST_MUTATION](state, payload) {
      // code 로 들어오는 값을 기준 값으로 (value)
      const arr = payload.items;

      arr.forEach((item) => {
        item.value = item.code;
      });

      state.calculateRangeList = arr;
    },
    [CONTRACT_CALCULATE_TYPE_LIST_MUTATION](state, payload) {
      // code 로 들어오는 값을 기준 값으로 (value)
      const arr = payload.items;

      arr.forEach((item) => {
        item.value = item.code;
      });

      state.calculateTypeList = arr;
    },
    [CONTRACT_PARTNER_INSPECT_TYPE_LIST_MUTATION](state, payload) {
      // code 로 들어오는 값을 기준 값으로 (value)
      const arr = payload.items;

      arr.forEach((item) => {
        item.value = item.code;
      });

      state.calculateStatusList = arr;
    },
    [CONTRACT_CONDITION_TYPE_LIST_MUTATION](state, payload) {
      // code 로 들어오는 값을 기준 값으로 (value)
      const arr = payload.items;

      arr.forEach((item) => {
        item.value = item.code;
      });

      state.conditionTypeList = arr;
    },
  },
};
