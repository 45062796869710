const sideMenuId = {
  DRAFT: "draft",
  APPROVAL: "appr",
  CC: "cc",
  MANAGEMENT: "admin",
  MANAGEMENT_STATEMENT: "statement",

  DRAFT_TEMP: "draftTempDocs", // 기안 > 임시저장
  DRAFT_ING: "draftIngDocs", // 기안 > 승인 요청 중
  DRAFT_COMPLETE: "draftCompleteDocs", // 기안 > 승인 완료
  DRAFT_REJECT: "draftRejectDocs", // 기안 > 승인 반려

  APPROVAL_BEFORE: "apprBeforeDocs", // 결재 문서함 > 승인 대기
  APPROVAL_RECEIVE: "apprReceiveDocs", // 결재 문서함 > 승인 확인
  APPROVAL_ING: "apprIngDocs", // 결재 문서함 > 승인 처리 중
  APPROVAL_COMPLETE: "apprCompleteDocs", // 결재 문서함 > 승인 완료
  APPROVAL_REJECT: "apprRejectDocs", // 결재 문서함 > 신청 반려

  CC_ING: "ccIngDocs", // 참조 > 진행중 문서
  CC_COMPLETE: "ccCompleteDocs", // 참조 > 처리된 문서

  STATEMENT_MANAGEMENT: "statementManagement", // 정산서 등록 관리

  MANAGEMENT_PUBLIC_APPROVAL_LINE: "publicApprovalLine", // 공용 결재선 관리
  MANAGEMENT_PUBLIC_APPROVAL_LINE_WRITE: "publicApprovalLineEdit",
  MANAGEMENT_ACCOUNT: "payAccountList", // 계정 관리
  MANAGEMENT_USE_GUIDE: "useGuideList", // 가이드 관리
};

export default sideMenuId;
