import { GET_EVALUATE_PROGRESS_STATUS_LIST, GET_EVALUATE_PROGRESS_LIST } from "./action";
import { EVALUATE_PROGRESS_STATUS_MUTATION } from "./mutation";
import ApiService from "@/services/ApiService";
import apiPath from "@/services/apiPath";
import { CATEGORY_ACTION } from "../category/action";

export default {
  state: {
    statusList: [],
    businessTypeList: [],
    gubuns: [],
  },
  actions: {
    async [GET_EVALUATE_PROGRESS_STATUS_LIST](context) {
      if (context.state.statusList.length > 0) return; // 한번만 호출.

      const result = await ApiService.shared.getData(apiPath.EVALUATE_PROGRESS_STATUS_LIST);

      this.commit({
        type: EVALUATE_PROGRESS_STATUS_MUTATION,
        items: result.data || [],
      });
    },
    async [GET_EVALUATE_PROGRESS_LIST](context) {
      await context.dispatch(GET_EVALUATE_PROGRESS_STATUS_LIST);
      await context.dispatch(CATEGORY_ACTION.GET_LIST_0);
    },
  },
  mutations: {
    [EVALUATE_PROGRESS_STATUS_MUTATION](state, payload) {
      const arr = payload.items;

      arr.forEach((element) => {
        // element.value = element.code;
        element.id = element.code;
        element.text = element.desc;
      });

      state.statusList = arr;
    },
  },
};
