const paymentRouterPath = {
  PAYMENT: `/payment`,
  PAYMENT_VIEW_DETAIL: "/payment/draftViewDetail",
  PAYMENT_WRITE: "/payment/draft",
  PAYMENT_MODIFY: "/payment/tempDraft",

  PAYMENT_DRAFT_TEMP: "/payment/draftTempDocs",
  PAYMENT_DRAFT_ING: "/payment/draftIngDocs",
  PAYMENT_DRAFT_COMPLETE: "/payment/draftCompleteDocs",
  PAYMENT_DRAFT_REJECT: "/payment/draftRejectDocs",

  PAYMENT_APPROVAL_BEFORE: "/payment/apprBeforeDocs",
  PAYMENT_APPROVAL_RECEIVE: "/payment/apprReceiveDocs",
  PAYMENT_APPROVAL_ING: "/payment/apprIngDocs",
  PAYMENT_APPROVAL_COMPLETE: "/payment/apprCompleteDocs",
  PAYMENT_APPROVAL_REJECT: "/payment/apprRejectDocs",

  PAYMENT_CC_ING: "/payment/ccIngDocs",
  PAYMENT_CC_COMPLETE: "/payment/ccCompleteDocs",

  PAYMENT_DRAFT: "/payment/draftTempDocs",

  PAYMENT_DRAFT_ING_DETAIL: "/payment/draftIngDetail",
  PAYMENT_DRAFT_COMPLETE_DETAIL: "/payment/draftCompleteDetail",
  PAYMENT_DRAFT_REJECT_DETAIL: "/payment/draftRejectDetail",
  PAYMENT_DRAFT_REJECT_EDIT: "/payment/draftRejectEdit",

  PAYMENT_APPROVAL_BEFORE_DETAIL: "/payment/apprBeforeDetail",
  PAYMENT_APPROVAL_RECEIVE_DETAIL: "/payment/apprReceiveDetail",
  PAYMENT_APPROVAL_ING_DETAIL: "/payment/apprIngDetail",
  PAYMENT_APPROVAL_COMPLETE_DETAIL: "/payment/apprCompleteDetail",
  PAYMENT_APPROVAL_REJECT_DETAIL: "/payment/apprRejectDetail",

  PAYMENT_CC_ING_DETAIL: "/payment/ccIngDetail",
  PAYMENT_CC_COMPLETE_DETAIL: "/payment/ccCompleteDetail",

  PAYMENT_SYSTEM: `/payment/system`, //관리
  PAYMENT_SYSTEM_PUBLIC_LINE_LIST: "/payment/system/publicLine", //결제선리스트
  PAYMENT_SYSTEM_PUBLIC_LINE_WRITE: "/payment/system/publicLineEdit", //결제선리스트

  PAYMENT_SYSTEM_ACCOUNT_LIST: "/payment/system/payAccountList",
  PAYMENT_SYSTEM_ACCOUNT_WRITE: "/payment/system/payAccountEdit",
  PAYMENT_SYSTEM_USE_GUIDE_LIST: "/payment/system/useGuideList", //guide리스트
  PAYMENT_SYSTEM_USE_GUIDE_WRITE: "/payment/system/useGuideEdit", //guide리스트
};

export default paymentRouterPath;
