import Vue from 'vue';
import '@/mainCore.js';

import ligRouterPath from '@LIG/router/ligRouterPath';
Vue.prototype.$routerPath = ligRouterPath;
Vue.prototype.$isLocal = process.env.NODE_ENV === 'local';
Vue.prototype.$isDevlop = process.env.NODE_ENV === 'development';
Vue.prototype.$isProd = process.env.NODE_ENV === 'production';
Vue.prototype.$isLIG = process.env.VUE_APP_NAME === 'LIG';

import App from '@/App.vue';
import ligRouter from './router/ligRouter.js';
import ligStore from './store/ligStore';

Vue.config.productionTip = false;
Vue.config.devtools = true;

new Vue({
  router: ligRouter,
  store: ligStore,
  render: (h) => h(App),
}).$mount('#app');
