import _ from "lodash";
import { make00 } from "@/utils/stringUtils";
import moment from "moment";

export function currency(value) {
  if (value === "") return "";
  if (!value) return "0";
  value = value.toString();
  return value.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
}
export function cardNumber(value) {
  if (!value) return "";
  value = value.toString();
  return value.replace(/([0-9]{4})([0-9]{4})([0-9]{4})([0-9]{4})/, "$1-$2-$3-$4");
}
export function date(value) {
  if (!value) return "";
  value = value.toString();
  return value.substr(0, 4) + "-" + value.substr(4, 2) + "-" + value.substr(6, 2);
}
export function dateYm(value) {
  if (!value) return "";
  value = value.toString();
  return value.substr(0, 4) + "-" + value.substr(4, 2);
}

export function time(value) {
  if (!value) return "";
  value = value.toString();

  return value.substr(0, 2) + ":" + value.substr(2, 2) + ":" + value.substr(4, 2);
}
export function employee(cmpCode, empName, accountId) {
  if (!empName) return "";
  if (!accountId) return "";
  if (cmpCode === "dkt") {
    return empName + "(" + accountId + ")";
  } else {
    return empName + "(" + accountId + ")";
  }
}

export function orgTreeNode(cmpCode, deptName, accountId, EmpName) {
  if ((accountId && !EmpName) || (!accountId && !deptName)) return "";
  if (cmpCode === "dkt") {
    if (accountId) {
      return _.unescape(EmpName + "(" + accountId + ")");
    } else {
      return _.unescape(deptName);
    }
  } else {
    if (accountId) {
      return _.unescape(EmpName + "(" + accountId + ")");
    } else {
      return _.unescape(deptName);
    }
  }
}

export function toInteger(value) {
  return parseInt(value);
}

export function idWithNameByObj(obj) {
  if (!obj) return "";

  if (obj.loginId && obj.name) {
    return idWithName(obj.loginId, obj.name);
  } else if (obj.accountId && (obj.personName || obj.displayName)) {
    return idWithName(obj.accountId, obj.personName || obj.displayName);
  }
}

export function idWithName(loginId, name) {
  if (!name) return loginId;
  if (!loginId) return name;

  return `${name} (${loginId})`;
}

export function idWithNameIfNullDash(loginId, name) {
  const result = idWithName(loginId, name);

  return result || "-";
}

export function dateTimeStringFormat(yyyymmddhhmmss, divDate = "-", divTime = ":") {
  if (!yyyymmddhhmmss || yyyymmddhhmmss.length < 8) return "";

  return moment(yyyymmddhhmmss, "YYYYMMDDhhmmss").format(
    `YYYY${divDate}MM${divDate}DD HH${divTime}mm${divTime}ss`,
  );
}

export function dateTimeStringFormatIfNullDash(yyyymmddhhmmss, divDate = "-", divTime = ":") {
  const result = dateTimeStringFormat(yyyymmddhhmmss, divDate, divTime);

  return result || "-";
}

export function dateTimeStringKoreanFormat(yyyymmddhhmmss) {
  if (!yyyymmddhhmmss || yyyymmddhhmmss.length < 8) return "";

  const year = yyyymmddhhmmss.slice(0, 4);
  const month = yyyymmddhhmmss.slice(4, 6);
  const date = yyyymmddhhmmss.slice(6, 8);

  if (yyyymmddhhmmss.length < 14) {
    return `${year}년 ${month}월 ${date}일 `;
  }

  const hour = yyyymmddhhmmss.slice(8, 10);
  const minute = yyyymmddhhmmss.slice(10, 12);
  // const second = yyyymmddhhmmss.slice( 12,14 );

  const ret = `${year}년 ${month}월 ${date}일 ${hour}시 ${minute}분`;

  return ret;
}

export function departmentText(deptPathName) {
  if (!deptPathName) return "";

  const arr = deptPathName.split(";");

  if (arr.length < 2) return deptPathName;

  arr.pop();

  return arr.join(" - ").trim();
}

export function categoryText(categoryName) {
  if (!categoryName) return "";
  const arr = categoryName.split(",");
  if (arr.length < 2) return categoryName;
  arr.pop();

  return arr.join(" > ").trim();
}

export function percentValue(value) {
  if (!value) return "";

  return `${value}%`;
}

export function monthValue(value) {
  if (!value) return "";

  return `${value} 개월`;
}

// export function add1000Comma(value) {
//   if (value === 0) return '0';
//
//   if (!value) return value;
//
//   const strMoney = String(value);
//
//   if (strMoney.indexOf(',') > -1) return strMoney;
//
//   let ret = '';
//   let nFromEnd;
//   let char;
//
//   for (var i = 0; i < strMoney.length; ++i) {
//     char = strMoney[i];
//
//     nFromEnd = strMoney.length - 1 - i;
//
//     ret += char;
//     if (nFromEnd !== 0 && nFromEnd % 3 === 0 && char !== '-') {
//       ret += ',';
//     }
//   }
//
//   return ret;
// }

export function replaceNlbr(item) {
  if (!item) return "";

  let ret = item.replace(/(?:\r\n|\r|\n)/g, "<br />");
  ret = ret.split("\n").join("<br />");

  return ret;
}

export function addCommaForMoney(value) {
  if (value === 0) return "0";

  if (!value) return value;

  const strMoney = String(value);

  if (strMoney.indexOf(",") > -1) return strMoney;

  let ret = "";
  let nFromEnd;
  let char;

  for (let i = 0; i < strMoney.length; ++i) {
    char = strMoney[i];

    nFromEnd = strMoney.length - 1 - i;

    ret += char;
    if (nFromEnd !== 0 && nFromEnd % 3 === 0 && char !== "-") {
      ret += ",";
    }
  }

  return ret;
}

// 1231212312 -> 123-12-12312
export function corporateNum(value) {
  if (value.length < 10 || value.indexOf("-") > -1) return value;
  const corporateNum1 = value.slice(0, 3);
  const corporateNum2 = value.slice(3, 5);
  const corporateNum3 = value.slice(5, 10);

  return `${corporateNum1}-${corporateNum2}-${corporateNum3}`;
}

// 9301082000000 -> 930108-2000000
export function personalNum(value) {
  if (value.length < 13 || value.indexOf("-") > -1) return value;
  const personalNum1 = value.slice(0, 6);
  const personalNum2 = value.slice(6, 13);

  return `${personalNum1}-${personalNum2}`;
}

export function corporateOrPersonalNum(value) {
  if ((value.length != 10 && value.length != 13) || value.indexOf("-") > -1) return value;
  if (value.length == 10) {
    return corporateNum(value);
  } else {
    return personalNum(value);
  }
}
export function taxInvoceNumber(value) {
  let ret = "";
  if (value) {
    const first = value.slice(0, 8);
    const secend = value.slice(8, 16);
    const third = value.slice(16, 24);
    ret = `${first}-${secend}-${third}`;
  } else {
    ret = "";
  }
  return ret;
}

export function dateStringFormat(yyyymmdd, div = "-") {
  if (!yyyymmdd || yyyymmdd.length < 8) return "";

  const year = yyyymmdd.slice(0, 4);
  const month = yyyymmdd.slice(4, 6);
  const date = yyyymmdd.slice(6, 8);

  const ret = `${year}${div}${month}${div}${date}`;

  return ret;
}

export function strDateTimeSplit(strDate) {
  const year = strDate.substr(0, 4);
  const month = strDate.substr(4, 2);
  const day = strDate.substr(6, 2);

  const hour = strDate.substr(8, 2);
  const minutes = strDate.substr(10, 2);

  return `${year}-${month}-${day} ${hour}:${minutes}`;
}

export function dateToStringHasDiv(dateTarget, strDiv = "-") {
  if (!dateTarget) return;
  const year = dateTarget.getFullYear();
  let month = dateTarget.getMonth() + 1;
  let date = dateTarget.getDate();

  month = make00(month);
  date = make00(date);

  const ret = `${year}${strDiv}${month}${strDiv}${date}`;

  return ret;
}

export function nullToDash(string) {
  let ret = "";
  if (string === null || string === undefined || !Boolean(string)) {
    ret = "-";
  } else {
    ret = string;
  }

  return ret;
}

export function assetStatusName(status) {
  let ret = "";
  switch (status) {
    case "C":
      ret = "사용중";
      break;
    case "R":
      ret = "대기";
      break;
    case "S":
      ret = "사용중지";
      break;
    default:
      ret = "-";
      break;
  }
  return ret;
}
