export const GET_CONTRACT_STATUS_LIST = "GET_CONTRACT_STATUS_LIST";
export const GET_CONTRACT_TYPE_LIST = "GET_CONTRACT_TYPE_LIST";
export const GET_CONTRACT_PART_LIST = "GET_CONTRACT_PART_LIST";
export const GET_CONTRACT_REDUCE_LIST = "GET_CONTRACT_REDUCE_LIST";
export const GET_CONTRACT_KEYWORD_LIST = "GET_CONTRACT_KEYWORD_LIST";

export const GET_CONTRACT_CALCULATE_RANGE_LIST = "GET_CONTRACT_CALCULATE_RANGE_LIST";
export const GET_CONTRACT_CALCULATE_TYPE_LIST = "GET_CONTRACT_CALCULATE_TYPE_LIST";
export const GET_CONTRACT_PARTNER_INSPECT_TYPE_LIST = "GET_CONTRACT_PARTNER_INSPECT_TYPE_LIST";
export const GET_CONTRACT_CONDITION_TYPE_LIST = "GET_CONTRACT_CONDITION_TYPE_LIST";

export const GET_CONTRACT_COMPANY_CODE_LIST = "GET_CONTRACT_COMPANY_CODE_LIST";

export const GET_CONTRACT_WRITE = "GET_CONTRACT_WRITE";
export const GET_CONTRACT_LIST = "GET_CONTRACT_LIST";
