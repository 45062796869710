import ApiService from '@/services/ApiService';
import apiPath from '@/services/apiPath';
import { GET_PROJECTLIST_ACTION } from './action';
import { PROJECTLIST_MUTATION } from './mutation';

export default {
  state: {
    projectList: [],
  },
  actions: {
    async [GET_PROJECTLIST_ACTION](context) {
      if (context.state.projectList.length > 0) return; // 한번만 호출.

      const result = await ApiService.shared.getData(apiPath.PROJECT_CODE);
      const { data = [] } = result;
      if (data && data.length > 0) {
        context.commit({
          type: PROJECTLIST_MUTATION,
          list: data,
        });
      } else {
        context.commit({
          type: PROJECTLIST_MUTATION,
          list: [],
        });
      }
    },
  },
  mutations: {
    [PROJECTLIST_MUTATION](state, payload) {
      //console.log('projectList :>> ', state, payload);
      const { list } = payload;
      state.projectList = Object.freeze(
        list.map((item, index) => Object.freeze({ ...item, index: index })),
      );
    },
  },
};
