import ApiService from "@/services/ApiService";
import apiPath from "@/services/apiPath";
import { MENU_ROLE_ACTION, MENU_ROLE_PARTNER_ACTION, MENU_ROLE_SUPER_ACTION } from "./action";
import { MENU_ROLE_MUTATION, MENU_ROLE_RESET_MUTATION } from "./mutation";
import menuId from "@/constants/menuId";
import { menuData as adminMenuData } from "@/constants/admin/menuData";
import { menuData as userMenuData } from "@/constants/user/menuData";

export default {
  state: {
    roleList: [],
  },
  getters: {
    getRoleList: (state) => state.roleList,
  },
  mutations: {
    [MENU_ROLE_MUTATION](state, payload) {
      const arr = [];
      const { items, isConst } = payload;
      items.forEach((item) => {
        if (isConst) {
          arr.push(menuId[item]);
        } else {
          arr.push(item);
        }
      });
      state.roleList = arr;
    },
    [MENU_ROLE_RESET_MUTATION](state) {
      state.roleList = [];
    },
  },
  actions: {
    async [MENU_ROLE_ACTION](context) {
      // if (context.state.roleList.length > 0) return; // 한번만 호출.

      const result = await ApiService.shared.getData(apiPath.ROLE_DETAIL);
      const { data = [] } = result;

      if (data.length > 0) {
        context.commit({
          type: MENU_ROLE_MUTATION,
          isConst: true,
          items: data[0].menuIdList || [],
        });
      }
    },
    async [MENU_ROLE_SUPER_ACTION](context) {
      // if (context.state.roleList.length > 0) return; // 한번만 호출.

      const tmp = [];

      adminMenuData.forEach((main) => {
        if (main.arrSub?.length > 0) {
          main.arrSub.forEach((sub) => {
            if (sub.roleInfos?.length > 0) {
              sub.roleInfos.forEach((role) => {
                tmp.push(role.menuId);
              });
            }
          });
        }
      });

      // 권한 하드코딩 > 슈퍼관리자는 권한을 안내려줌
      context.commit({
        type: MENU_ROLE_MUTATION,
        items: tmp,
      });
    },
    async [MENU_ROLE_PARTNER_ACTION](context) {
      // if (context.state.roleList.length > 0) return; // 한번만 호출.

      const tmp = [];

      userMenuData.forEach((main) => {
        if (main.roleInfos?.length > 0) {
          main.roleInfos.forEach((role) => {
            tmp.push(role.menuId);
          });
        }
      });

      // 권한 하드코딩 > 파트너는 권한을 안내려줌
      context.commit({
        type: MENU_ROLE_MUTATION,
        items: tmp,
      });
    },
  },
};
