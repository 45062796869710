import { COMPANY_ACTION } from "./action";
import { COMPANY_MUTATION } from "./mutation";
import ApiService from "@/services/ApiService";
import apiPath from "@/services/apiPath";

const { GET_COMPANY_LIST } = COMPANY_ACTION;
const { COMPANY_LIST } = COMPANY_MUTATION;

export default {
  state: {
    companyList: [],
  },
  actions: {
    async [GET_COMPANY_LIST](context) {
      if (context.state.companyList.length > 0) return; // 한번만 호출.
      const result = await ApiService.shared.getData(apiPath.COMPANY);

      this.commit({
        type: COMPANY_LIST,
        items: result.data || [],
      });
    },
  },
  mutations: {
    [COMPANY_LIST](state, payload) {
      const arr = payload.items;

      arr.forEach((element) => {
        // "companyCode": "DK",
        // "companyKey": "K001",
        // "companyName": "카카오"

        element.code = element.companyCode;
        element.desc = element.companyName;
      });

      state.companyList = arr;
    },
  },
};
