import Vue from "vue";
import VScrollLock from "v-scroll-lock";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/ko";
import Fragment from "vue-fragment";
import _ from "lodash";
import moment from "moment";
import vueDebounce from "vue-debounce";
import vClickOutside from "v-click-outside";
import filters from "@/filters/index.js";
import "@/plugins/axios";
import apiPath from "@/services/apiPath";

import paymentRouterPath from "@/router/paymentRouterPath";

import useToast from "@/plugins/toast.js";

Vue.prototype.$paymentRouterPath = paymentRouterPath;

Vue.prototype.$apiPath = apiPath;
Vue.prototype._ = _;
Vue.prototype.moment = moment;
Vue.prototype.$windowOpen = function (path, option = "") {
  if (!path) {
    alert("경로가 올바르지 않습니다.");
    return;
  }
  const screenWidth = window.screen.width;
  window.open(
    path,
    `${this.$route.path}_${this.moment().valueOf()}`,
    `popup=yes,width=${screenWidth},height=800,${option}`,
  );
};

Vue.prototype.$tabOpen = function (path) {
  if (!path) {
    alert("경로가 올바르지 않습니다.");
    return;
  }
  window.open(path, `${this.$route.path}_${this.moment().valueOf()}`);
};
Vue.prototype.$assetsPath = process.env.VUE_APP_ASSETS_PATH;

Vue.use(useToast);
Vue.use(ElementUI, { locale });
Vue.use(VScrollLock);
Vue.use(Fragment.Plugin);
Vue.use(vueDebounce);
Vue.use(vClickOutside);
Vue.use(filters);
