import {
  GET_ORDER_STATUS_LIST,
  GET_ORDER_KEYWORD_LIST,
  ORDER_LIST_ACTION,
  ORDER_WRITE_ACTION,
} from "./action";
import { ORDER_STATUS_LIST_MUTATION, ORDER_KEYWORD_LIST_MUTATION } from "./mutation";
import ApiService from "@/services/ApiService";
import apiPath from "@/services/apiPath";

import { CATEGORY_ACTION } from "../category/action";

export default {
  state: {
    statusList: [],
    keywordList: [],
  },
  actions: {
    async [GET_ORDER_STATUS_LIST](context) {
      if (context.state.statusList.length > 0) return; // 한번만 호출.

      const result = await ApiService.shared.getData(apiPath.ORDER_STATUS_LIST);

      this.commit({
        type: ORDER_STATUS_LIST_MUTATION,
        items: result.data || [],
      });
    },
    async [GET_ORDER_KEYWORD_LIST](context) {
      if (context.state.keywordList.length > 0) return; // 한번만 호출.

      //const result = await ApiService.shared.getData(apiPath.ITEM_STATUS_LIST); //API가 없어서 front에서 하드코딩함
      const result = {
        code: "",
        data: [
          { id: "projectName", code: "projectName", desc: "프로젝트명" },
          { id: "companyName", code: "companyName", desc: "파트너사" },
          { id: "title", code: "title", desc: "발주서명" },
          { id: "orderCid", code: "orderCid", desc: "발주번호" },
          { id: "purchase", code: "purchase", desc: "구매담당자" },
          { id: "inspect", code: "inspect", desc: "검수담당자" },
        ],
        text: "",
      };
      const { code, data, text } = result;
      this.commit({
        type: ORDER_KEYWORD_LIST_MUTATION,
        list: data || [],
      });
    },
    async [ORDER_LIST_ACTION](context) {
      await context.dispatch(GET_ORDER_STATUS_LIST);
      await context.dispatch(GET_ORDER_KEYWORD_LIST);
      await context.dispatch(CATEGORY_ACTION.GET_LIST_0);
    },
    async [ORDER_WRITE_ACTION](context) {
      await context.dispatch(CATEGORY_ACTION.GET_LIST_0);
    },
  },
  mutations: {
    [ORDER_STATUS_LIST_MUTATION](state, payload) {
      // code 로 들어오는 값을 기준 값으로 (value)
      const arr = payload.items;

      arr.forEach((item) => {
        item.value = item.code;
      });

      state.statusList = arr;
    },
    [ORDER_KEYWORD_LIST_MUTATION](state, payload) {
      //발주관리 검색어
      const { list } = payload;
      state.keywordList = Object.freeze(
        list.map((item, index) => Object.freeze({ ...item, index: index })),
      );
    },
  },
};
