/**
 * LIG 공급사 전자사인 인증서 호출 Store
 *
 * 설치 체크
 this.$store.dispatch('ACTION_SETUP_TRADESIGN', {
    onInitTradeSign,  // 설치 성공후 콜백함수
  });
 * 인증서 API 호출
 this.$store.dispatch('ACTION_SEND_TRADESIGN', {
    signData: signData, //인증서에 넘겨주는 DATA
    returnCallback: this.resultCallback, //인증서 결과값 리턴 받는 함수
  });
 *
 */

const ACTION_SETUP_TRADESIGN = 'ACTION_SETUP_TRADESIGN';
const ACTION_SEND_TRADESIGN = 'ACTION_SEND_TRADESIGN';
const MUTATION_SETUP_TRADESIGN = 'MUTATION_SETUP_TRADESIGN';
const MUTATION_SEND_TRADESIGN = 'MUTATION_SEND_TRADESIGN';

export default {
  state: {
    data: null,
    ssn: '',
  },
  mutations: {
    [MUTATION_SETUP_TRADESIGN](state, { payload }) {
      //onInitTradeSign  설치 성공후 콜백함수
      const { onInitTradeSign } = payload;

      if (onInitTradeSign) {
        nxTSPKI.onInit(function () {
          onInitTradeSign();
        });
      }
      nxTSPKI.init(true);
    },
    [MUTATION_SEND_TRADESIGN](state, { payload }) {
      // signData //인증서에 넘져주는 DATA 뭐든들어감
      // returnCallback //인증서 인증후 결과값 리턴 데이터;
      const { signData, returnCallback } = payload;
      state.data = signData || '';
      const data = signData;
      let options = {};
      if (Object.hasOwn(state, 'ssn')) {
        options.ssn = state.ssn;
      }

      const signCompleteCallback = (res) => {
        if (res.code == 0) {
          returnCallback(res.data);
          /* 성공여부 테스트 API */
          // const fatchHeaders = new Headers();
          // fatchHeaders.append('Content-Type', 'application/json');
          // const requestOptions = {
          //   method: 'POST',
          //   headers: fatchHeaders,
          //   body: res.data.signedData,
          //   redirect: 'follow',
          // };
          // fetch('http://117.52.83.150/api/ligs/cmmn/signeVeryfy', requestOptions)
          //   .then((response) => response.data)
          //   .then((result) => {
          //     console.log('=========');
          //     console.log(result);
          //     console.log('//=========');
          //     //result.data.certsList[index].expYn
          //     //expYn 값이 true면 성공임
          //   })
          //   .catch((error) => alert(error));
        } else {
          alert(`error code = ${res.code}, message = ${res.errorMessage}`);
        }
      };
      nxTSPKI.signData(data, options, signCompleteCallback);
    },
  },
  actions: {
    [ACTION_SETUP_TRADESIGN](context, payload) {
      this.commit({ type: MUTATION_SETUP_TRADESIGN, payload });
    },
    [ACTION_SEND_TRADESIGN](context, payload) {
      this.commit({
        type: MUTATION_SEND_TRADESIGN,
        payload,
      });
    },
  },
};
