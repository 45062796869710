import ligRouterPath from '@LIG/router/ligRouterPath';
import menuId from '@/constants/menuId';

const menuData = [
  {
    title: '공지사항',
    link: ligRouterPath.SYSTEM_NOTICE,
    roleInfos: [
      {
        menuId: menuId.SYSTEM_NOTICE_READ,
      },
    ],
  },
  {
    title: '견적현황',
    link: ligRouterPath.ESTIMATE_LIST,
    roleInfos: [
      {
        menuId: menuId.ESTIMATE_READ,
      },
      {
        menuId: menuId.ESTIMATE_WRITE,
      },
    ],
  },
  {
    title: '공고현황',
    link: ligRouterPath.ANNOUNCE,
    roleInfos: [
      {
        menuId: menuId.ANNOUNCE_READ,
      },
      {
        menuId: menuId.ANNOUNCE_WRITE,
      },
    ],
  },
  {
    title: '입찰참여내역',
    link: ligRouterPath.BID,
    roleInfos: [
      {
        menuId: menuId.BID_READ,
      },
      {
        menuId: menuId.BID_WRITE,
      },
    ],
  },
  {
    title: '계약현황',
    link: ligRouterPath.CONTRACT,
    roleInfos: [
      {
        menuId: menuId.CONTRACT_READ,
      },
    ],
  },
  {
    title: '발주현황',
    link: ligRouterPath.ORDER,
    roleInfos: [
      {
        menuId: menuId.ORDER_READ,
      },
    ],
  },
  {
    title: '검수현황',
    link: ligRouterPath.INSPECT,
    roleInfos: [
      {
        menuId: menuId.INSPECT_READ,
      },
      {
        menuId: menuId.INSPECT_WRITE,
      },
    ],
  },
  {
    title: '세금계산서현황',
    link: ligRouterPath.TRANSACTIONREPORT_LIST,
    roleInfos: [
      {
        menuId: menuId.TRANSACTIONREPORT_READ,
      },
      {
        menuId: menuId.TRANSACTIONREPORT_WRITE,
      },
    ],
  },
];

export { menuData };
