import { OPEN_SIDE_MENU, TOGGLE_SIDE_MENU } from "./action";
import { SIDE_MENU_OPEN_MUTATION, SIDE_MENU_TOGGLE_MUTATION } from "./mutation";
import sideMenuId from "@/constants/sideMenuId";

export default {
  state: {
    menuOpenStatus: {
      draft: false,
      approval: false,
      cc: false,
      admin: false,
    },
  },
  actions: {
    [OPEN_SIDE_MENU](context, payload) {
      const { sideMenuId } = payload;

      this.commit({
        type: SIDE_MENU_OPEN_MUTATION,
        sideMenuId,
      });
    },
    [TOGGLE_SIDE_MENU](context, payload) {
      const { sideMenuId } = payload;

      this.commit({
        type: SIDE_MENU_TOGGLE_MUTATION,
        sideMenuId,
      });
    },
  },
  mutations: {
    [SIDE_MENU_OPEN_MUTATION](state, payload) {
      switch (payload.sideMenuId) {
        case sideMenuId.DRAFT:
          state.menuOpenStatus.draft = true;
          break;
        case sideMenuId.APPROVAL:
          state.menuOpenStatus.approval = true;
          break;
        case sideMenuId.CC:
          state.menuOpenStatus.cc = true;
          break;
        case sideMenuId.MANAGEMENT:
          state.menuOpenStatus.admin = true;
          break;
      }
    },
    [SIDE_MENU_TOGGLE_MUTATION](state, payload) {
      switch (payload.sideMenuId) {
        case sideMenuId.DRAFT:
          state.menuOpenStatus.draft = !state.menuOpenStatus.draft;
          break;
        case sideMenuId.APPROVAL:
          state.menuOpenStatus.approval = !state.menuOpenStatus.approval;
          break;
        case sideMenuId.CC:
          state.menuOpenStatus.cc = !state.menuOpenStatus.cc;
          break;
        case sideMenuId.MANAGEMENT:
          state.menuOpenStatus.admin = !state.menuOpenStatus.admin;
          break;
      }
    },
  },
};
