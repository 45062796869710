import { NOTIFY_LIST_ACTION, GET_NOTIFY_STATUS_LIST, GET_NOTIFY_TYPE_LIST } from "./action";
import { NOTIFY_STATUS_LIST_MUTATION, NOTIFY_TYPE_LIST_MUTATION } from "./mutation";
import ApiService from "@/services/ApiService";
import apiPath from "@/services/apiPath";

export default {
  state: {
    statusList: [],
    typeList: [],
  },
  actions: {
    async [GET_NOTIFY_STATUS_LIST](context) {
      if (context.state.statusList.length > 0) return; // 한번만 호출.

      const result = await ApiService.shared.getData(apiPath.NOTIFY_STATUS);

      this.commit({
        type: NOTIFY_STATUS_LIST_MUTATION,
        items: result.data || [],
      });
    },
    async [GET_NOTIFY_TYPE_LIST](context) {
      if (context.state.typeList.length > 0) return; // 한번만 호출.

      const result = await ApiService.shared.getData(apiPath.NOTIFY_TYPE);

      this.commit({
        type: NOTIFY_TYPE_LIST_MUTATION,
        items: result.data || [],
      });
    },
    async [NOTIFY_LIST_ACTION](context) {
      await context.dispatch(GET_NOTIFY_STATUS_LIST);

      await context.dispatch(GET_NOTIFY_TYPE_LIST);
    },
  },
  mutations: {
    [NOTIFY_STATUS_LIST_MUTATION](state, payload) {
      // code 로 들어오는 값을 기준 값으로 (value)
      const arr = payload.items;

      arr.forEach((item) => {
        item.value = item.code;
      });

      state.statusList = arr;
    },
    [NOTIFY_TYPE_LIST_MUTATION](state, payload) {
      // code 로 들어오는 값을 기준 값으로 (value)
      const arr = payload.items;

      arr.forEach((item) => {
        item.value = item.code;
      });

      state.typeList = arr;
    },
  },
};
