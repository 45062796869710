<template>
  <div
    v-if="isToast"
    class="toast"
    :class="isContents ? 'toast--fade-in' : 'toast--fade-out'"
    v-html="contents"
  ></div>
</template>

<script>
export default {
  name: "ToastPopop",
  data() {
    return {
      isToast: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.toast {
  width: 80%;
  margin: 30px auto 0;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  &--fade {
    &-in {
      animation: animateIn 0.5s linear 0s;
    }
    &-out {
      animation: animateOut 0.5s linear 0s;
      opacity: 0;
    }
  }
}
@keyframes animateIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes animateOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
