import { GET_MANAGER_STATUS_LIST } from "./action";
import { MANAGER_STATUS_LIST_MUTATION } from "./mutation";
import ApiService from "@/services/ApiService";
import apiPath from "@/services/apiPath";

export default {
  state: {
    statusList: [],
  },
  actions: {
    async [GET_MANAGER_STATUS_LIST](context) {
      if (context.state.statusList.length > 0) return; // 한번만 호출.

      const result = await ApiService.shared.getData(apiPath.ACCOUNT_STATUS_LIST);

      this.commit({
        type: MANAGER_STATUS_LIST_MUTATION,
        items: result.data || [],
      });
    },
  },
  mutations: {
    [MANAGER_STATUS_LIST_MUTATION](state, payload) {
      // code 로 들어오는 값을 기준 값으로 (value)
      const arr = payload.items;

      arr.forEach((item) => {
        item.value = item.code;
      });

      state.statusList = arr;
    },
  },
};
