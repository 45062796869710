import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import modules, { ALERT_ACTION, ESTIMATE_LIST_ACTION, PURCHASE_LIST_ACTION } from '@/store/modules';

import menu from '@LIG/store/modules/menu/menu';
import projectList from '@LIG/store/modules/projectList/projectList';
import statistics from '@LIG/store/modules/statistics/statistics';
import tradeSign from '@LIG/store/modules/tradeSign';

Vue.use(Vuex);

const ligStore = new Vuex.Store({
  modules: {
    ...modules,
    menu,
    tradeSign,
    projectList,
    statistics,
  },
  plugins: [createPersistedState()],
  state: {},
  mutations: {},
  actions: {
    // 비동기 처리
  },
});

export default ligStore;

export { ALERT_ACTION, PURCHASE_LIST_ACTION, ESTIMATE_LIST_ACTION };
