import { COMMON_CODE_ACTION } from "./action";
import { COMMON_CODE_MUTATION } from "./mutation";
import ApiService from "@/services/ApiService";
import apiPath from "@/services/apiPath";
// import { decodeHTMLEntities } from '@/utils/stringUtils'

const {
  GET_MOBILE_STATION,
  GET_PHONE_AREA,
  GET_EMAIL_VENDOR,
  GET_CURRENCY,
  GET_PAGE_RANGE,
  GET_QUANTITY,
  GET_BANK_CODE,
} = COMMON_CODE_ACTION;
const { MOBILE_STATION, PHONE_AREA, EMAIL_VENDOR, CURRENCY, PAGE_RANGE, QUANTITY, BANK_CODE } =
  COMMON_CODE_MUTATION;

export default {
  state: {
    mobileStation: [],
    phoneArea: [],
    emailVendor: [],
    currency: [],
    pageRange: [],
    quantity: [],
    bankCodeList: [],
  },
  actions: {
    async [GET_MOBILE_STATION](context) {
      if (context.state.mobileStation.length > 0) return; // 한번만 호출.

      const result = await ApiService.shared.getData(apiPath.MOBILE_STATION);

      this.commit({
        type: MOBILE_STATION,
        items: result.data || [],
      });
    },
    async [GET_PHONE_AREA](context) {
      if (context.state.phoneArea.length > 0) return; // 한번만 호출.

      const result = await ApiService.shared.getData(apiPath.PHONE_AREA);

      this.commit({
        type: PHONE_AREA,
        items: result.data || [],
      });
    },
    async [GET_EMAIL_VENDOR](context) {
      if (context.state.emailVendor.length > 0) return; // 한번만 호출.

      const result = await ApiService.shared.getData(apiPath.EMAIL_VENDOR);

      this.commit({
        type: EMAIL_VENDOR,
        items: result.data || [],
      });
    },
    async [GET_CURRENCY](context) {
      if (context.state.currency.length > 0) return; // 한번만 호출.

      const result = await ApiService.shared.getData(apiPath.CURRENCY);

      this.commit({
        type: CURRENCY,
        items: result.data || [],
      });
    },
    async [GET_PAGE_RANGE](context) {
      if (context.state.pageRange.length > 0) return; // 한번만 호출.

      const result = await ApiService.shared.getData(apiPath.PAGE_RANGE);

      this.commit({
        type: PAGE_RANGE,
        items: result.data || [],
      });
    },
    async [GET_QUANTITY](context) {
      if (context.state.quantity.length > 0) return; // 한번만 호출.

      const result = await ApiService.shared.getData(apiPath.QUANTITY);

      this.commit({
        type: QUANTITY,
        items: result.data || [],
      });
    },
    async [GET_BANK_CODE](context) {
      if (context.state.bankCodeList.length > 0) return; // 한번만 호출.

      const result = await ApiService.shared.getData(apiPath.BANK_CODE);

      this.commit({
        type: BANK_CODE,
        items: result.data || [],
      });
    },
  },
  mutations: {
    [MOBILE_STATION](state, payload) {
      const arr = payload.items;

      arr.forEach((element) => {
        element.desc = element.code;
      });

      state.mobileStation = arr;
    },
    [PHONE_AREA](state, payload) {
      const arr = payload.items;

      arr.forEach((element) => {
        element.desc = element.code;
      });

      state.phoneArea = arr;
    },
    [EMAIL_VENDOR](state, payload) {
      const arr = payload.items;

      arr.forEach((element) => {
        element.desc = element.code;
      });

      const arrDefault = [
        {
          code: "직접입력",
          desc: "직접입력",
        },
      ];

      state.emailVendor = arrDefault.concat(payload.items);
    },
    [CURRENCY](state, payload) {
      const arr = payload.items;

      arr.forEach((element, index) => {
        // 특수문자? 를 key값을 넣으니까 에러 남. 그래서 이 경우는 index -> id -> key.
        element.id = index;

        // currency랑 qauntity는 name이 키 값.
        element.code = element.name;

        // &euro; -> string
        // element.desc = decodeHTMLEntities( document, element.code );
      });

      state.currency = arr;
    },
    [PAGE_RANGE](state, payload) {
      const arr = payload.items;

      arr.forEach((element) => {
        // 문자열로 들어오는데 그냥 숫자로 바꾸겠다.
        element.code = Number.parseInt(element.code, 10);
      });

      state.pageRange = arr;
    },
    [QUANTITY](state, payload) {
      const arr = payload.items;

      state.quantity = arr;
    },
    [BANK_CODE](state, payload) {
      const arr = payload.items;

      state.bankCodeList = arr;
    },
  },
};
