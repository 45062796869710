import {
  GET_PARTNER_STATUS_LIST,
  GET_BUSINESS_TYPE_LIST,
  GET_PARTNER_GUBUNS_ACTION,
  PARTNER_VIEW_ACTION,
} from "./action";
import {
  PARTNER_STATUS_LIST_MUTATION,
  BUSINESS_TYPE_LIST_MUTATION,
  PARTNER_GUBUNS_MUTATION,
} from "./mutation";
import ApiService from "@/services/ApiService";
import apiPath from "@/services/apiPath";

export default {
  state: {
    statusList: [],
    businessTypeList: [],
    gubuns: [],
  },
  actions: {
    async [GET_PARTNER_STATUS_LIST](context) {
      if (context.state.statusList.length > 0) return; // 한번만 호출.

      const result = await ApiService.shared.getData(apiPath.PARTNER_STATUS_LIST);

      this.commit({
        type: PARTNER_STATUS_LIST_MUTATION,
        items: result.data || [],
      });
    },
    async [GET_BUSINESS_TYPE_LIST](context) {
      if (context.state.businessTypeList.length > 0) return; // 한번만 호출.

      const result = await ApiService.shared.getData(apiPath.PARTNER_BUSINESS_TYPE);

      this.commit({
        type: BUSINESS_TYPE_LIST_MUTATION,
        items: result.data || [],
      });
    },
    async [GET_PARTNER_GUBUNS_ACTION](context) {
      if (context.state.gubuns.length > 0) return; // 한번만 호출.

      const result = await ApiService.shared.getData(apiPath.PARTNER_GUBUNS);

      this.commit({
        type: PARTNER_GUBUNS_MUTATION,
        items: result.data || [],
      });
    },
    async [PARTNER_VIEW_ACTION](context) {
      await context.dispatch(GET_PARTNER_STATUS_LIST);

      await context.dispatch(GET_BUSINESS_TYPE_LIST);

      await context.dispatch(GET_PARTNER_GUBUNS_ACTION);
    },
  },
  mutations: {
    [PARTNER_STATUS_LIST_MUTATION](state, payload) {
      const arr = payload.items;

      arr.forEach((element) => {
        element.value = element.code;
      });

      state.statusList = arr;
    },
    [BUSINESS_TYPE_LIST_MUTATION](state, payload) {
      const arr = payload.items;

      arr.forEach((element) => {
        element.value = element.code;
      });

      state.businessTypeList = arr;
    },
    [PARTNER_GUBUNS_MUTATION](state, payload) {
      state.gubuns = payload.items;
    },
  },
};
