import commonCode from "./common/common";
import item from "./item/item";
import announce from "./announce/announce";
import draft from "./draft/draft";
import bid from "./bid/bid";
import order from "./order/order";
import partner from "./partner/partner";
import manager from "./manager/manager";
import inspect from "./inspect/inspect";
import category from "./category/category";
import company from "./company/company";
import contract from "./contract/contract";
import notify from "./notify/notify";
import performance from "./performance/performance";
import template from "./template/template";
import estimate, { ESTIMATE_LIST_ACTION } from "./estimate/estimate";
import purchase, { PURCHASE_LIST_ACTION } from "./purchase/purchase";
import transactionreport from "./transactionreport/transactionreport";

import alert, { ALERT_ACTION } from "./alert/alert";
import progressbar from "./progressbar/progressbar";

import CommStore from "./commStore";
import purchaseRequest from "./purchaseRequest/purchaseRequest";
import statement from "./statement/statement";
import menu from "./menu/menu";

const modules = {
  CommStore,
  item,
  announce,
  draft,
  bid,
  order,
  commonCode,
  partner,
  manager,
  inspect,
  category,
  company,
  contract,
  notify,
  performance,
  template,
  alert,
  progressbar,
  estimate,
  purchaseRequest,
  purchase,
  statement,
  transactionreport,
  menu,
};
export default modules;

export { ALERT_ACTION, PURCHASE_LIST_ACTION, ESTIMATE_LIST_ACTION };
