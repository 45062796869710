import Vue from "vue";
import apiPath from "./apiPath";

class ApiService {
  static instance;

  axios = Vue.prototype.$axios;

  static get shared() {
    if (this.instance) {
      return this.instance;
    }

    this.instance = new ApiService();
    return this.instance;
  }

  async getData(path) {
    const result = await this.get(path);
    return result || {};
  }

  async getFile(path, objExcelHeader = null, isNeedAuth = true) {
    const config = { isNeedAuth, responseType: "arraybuffer" };

    if (objExcelHeader) {
      config.params = { excelHeader: objExcelHeader };
    }

    const result = await this.get(path, config);
    return result || {};
  }

  async postFile(path, obj) {
    const config = { responseType: "arraybuffer" };

    const result = await this.post(path, obj, config);
    return result || {};
  }

  async login(obj, companyCode) {
    const config = { isNeedAuth: false, companyCodeForce: companyCode };

    const result = await this.post(apiPath.LOGIN, obj, config);
    return result;
  }

  async password(obj, companyCode) {
    const config = { isNeedAuth: false, companyCodeForce: companyCode };

    const result = await this.post(apiPath.MAIN_PARTNER_PASS_RESET, obj, config);
    return result || {};
  }

  async postData(path, obj) {
    const result = await this.post(path, obj);
    return result || {};
  }

  async postFiles(files) {
    let formData = new FormData();

    for (let i = 0; i < files.length; i += 1) {
      formData.append("file", files[i]);
    }

    const config = {
      contentType: "multipart/form-data",
    };

    const result = await this.post(apiPath.ATTACH_FILE, formData, config);

    return result || {};
  }

  async putData(path, obj) {
    const result = await this.put(path, obj);

    return result || {};
  }

  async deleteData(path, isNeedAuth = true) {
    const config = { isNeedAuth };

    const result = await this.delete(path, config);
    return result || {};
  }

  async get(url, config) {
    const result = await this.axios
      .get(url, config)
      .then((response) => {
        if (response.code && response.code !== "200") {
          this.getErrorAlert(response);
        }
        return response.data;
      })
      .catch((error) => {
        const ret = this.getErrorResponse(error.response);
        return ret;
      });

    return result;
  }

  async post(url, obj, config) {
    const result = await this.axios
      .post(url, obj, config)
      .then((response) => {
        if (response.code && response.code !== "200") {
          this.getErrorAlert(response);
        }
        return response.data;
      })
      .catch((error) => {
        const ret = this.getErrorResponse(error.response);
        return ret;
      });

    return result;
  }

  async put(url, obj, config) {
    const result = await this.axios
      .put(url, obj, config)
      .then((response) => {
        if (response.code && response.code != "200") {
          this.getErrorAlert(response);
        }
        return response.data;
      })
      .catch((error) => {
        const ret = this.getErrorResponse(error.response);
        return ret;
      });

    return result;
  }

  async delete(url, config) {
    const result = await this.axios
      .delete(url, config)
      .then((response) => {
        if (response.code && response.code != "200") {
          this.getErrorAlert(response);
        }
        return response.data;
      })
      .catch((error) => {
        const ret = this.getErrorResponse(error.response);
        return ret;
      });

    return result;
  }

  getErrorResponse = (errorResponse) => {
    const ret = {
      error: true,
      data: null,
      status: errorResponse ? errorResponse.status : "",
      text: errorResponse ? errorResponse.statusText : "",
    };
    return ret;
  };
  getErrorAlert = (response) => {
    console.error(response);
  };
}

export default ApiService;
