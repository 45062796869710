import { CATEGORY_ACTION } from "./action";
import { CATEGORY_MUTATION } from "./mutation";
import ApiService from "@/services/ApiService";

import apiPath from "@/services/apiPath";

const { GET_LIST_0, GET_LIST_1, GET_LIST_2 } = CATEGORY_ACTION;
const { LIST_0_MUTATION, LIST_1_MUTATION, LIST_2_MUTATION, HANDLE_ITEM_MUTATION } =
  CATEGORY_MUTATION;

export default {
  state: {
    categoryList0: [],
    categoryList1: [],
    categoryList2: [],
  },
  actions: {
    async [GET_LIST_0](context) {
      // 최상위 카테고리의 상위 카테고리 cateNum는 0.
      const cateNum = 0;

      // const arrTarget = this.state.category.categoryList0;
      // // 한번만 호출.
      // if( arrTarget.length > 0 )  return;

      const result = await ApiService.shared.getData(`${apiPath.CATEGORY}/${cateNum}`);

      this.commit({
        type: LIST_0_MUTATION,
        items: result.data || [],
        cateNum,
      });
    },
    async [GET_LIST_1](context, cateNum) {
      // const arrTarget = this.state.category.categoryList1;

      // const itemTarget = arrTarget.find( item => item.parentCateNum === cateNum );

      // if( itemTarget )  return;

      const result = await ApiService.shared.getData(`${apiPath.CATEGORY}/${cateNum}`);

      this.commit({
        type: LIST_1_MUTATION,
        items: result.data || [],
        cateNum,
      });
    },
    async [GET_LIST_2](context, cateNum) {
      // const arrTarget = this.state.category.categoryList2;

      // const itemTarget = arrTarget.find( item => item.parentCateNum === cateNum );

      // if( itemTarget )  return;

      const result = await ApiService.shared.getData(`${apiPath.CATEGORY}/${cateNum}`);

      this.commit({
        type: LIST_2_MUTATION,
        items: result.data || [],
        cateNum,
      });
    },
  },
  mutations: {
    [LIST_0_MUTATION](state, payload) {
      setCateValues(payload.items);

      state.categoryList0 = makeNewList(payload, state.categoryList0);
    },
    [LIST_1_MUTATION](state, payload) {
      setCateValues(payload.items);

      state.categoryList1 = makeNewList(payload, state.categoryList1);
    },
    [LIST_2_MUTATION](state, payload) {
      setCateValues(payload.items);

      state.categoryList2 = makeNewList(payload, state.categoryList2);
    },
  },
};

function makeNewList(payload, list) {
  const objNew = { parentCateNum: payload.cateNum, dataList: payload.items };

  const arr = list.slice(0);

  const nPrev = arr.findIndex((item) => item.parentCateNum === payload.cateNum);

  if (nPrev === -1) {
    arr.push(objNew);
  } else {
    arr[nPrev] = objNew;
  }

  return arr;
}

function setCateValues(items) {
  items.forEach((element) => {
    element.desc = element.title;
    element.code = element.cateNum;
  });
}
