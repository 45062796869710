import Vue from "vue";

import ToastPopup from "@/components/layout/popup/ToastPopup.vue";
const ToastPopupExtend = Vue.extend(ToastPopup);
var FLAG = true;
function toast(contents, duration = 2000) {
  if (FLAG === false) {
    return;
  }
  const $elToast = new ToastPopupExtend({
    el: document.createElement("div"),
    data() {
      return {
        contents: contents,
        isToast: true,
        isContents: true,
      };
    },
  });

  if (!document.querySelector(".toast")) {
    document.querySelector("#toastWrap").appendChild($elToast.$el);
  }

  //FLAG = false; // 무한으로 출력 true 일경우 하나만 출력

  setTimeout(() => {
    $elToast.isToast = false;
    FLAG = true;
  }, duration);
}

function useToast() {
  Vue.prototype.$toast = toast;
}

export default useToast;
