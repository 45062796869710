import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/ligStore';
import ligRouterPath from './ligRouterPath';
import LocalStorageManager from '@/LocalStorageManager';
import menuId from '@/constants/menuId';

import { ALERT_ACTION } from '@/store/modules/alert/action';
import { PROGRESS_END_ACTION } from '@/store/modules/progressbar/action';

import paymentRouter from '@/router/paymentRouter';
import {
  MENU_ROLE_ACTION,
  MENU_ROLE_PARTNER_ACTION,
  MENU_ROLE_SUPER_ACTION,
} from '@/store/modules/menu/action';

Vue.use(VueRouter);

const routes = [
  {
    path: ligRouterPath.LOGIN,
    meta: {
      isOnlyUser: true,
      requiresAuth: false,
    },
    component: () => import('@/pages/user/Login.vue'),
  },
  {
    path: ligRouterPath.LOGIN_ADMIN,
    meta: {
      isOnlyAdmin: true,
      requiresAuth: false,
    },
    component: () => import('@/pages/admin/Login.vue'),
  },
  {
    path: ligRouterPath.CAS_LOGIN_SUCCESS,
    meta: {
      isOnlyAdmin: true,
      requiresAuth: false,
    },
    component: () => import('@/pages/admin/LoginSuccess.vue'),
  },
  {
    path: ligRouterPath.JOIN,
    name: ligRouterPath.JOIN,
    meta: {
      isOnlyUser: true,
      requiresAuth: false,
    },
    component: () => import('@/pages/user/Join.vue'),
  },
  {
    path: ligRouterPath.JOIN_INQUIRY,
    meta: {
      isOnlyUser: true,
      requiresAuth: false,
    },
    component: () => import('@/pages/user/JoinInquiry.vue'),
  },

  {
    path: ligRouterPath.MY_INFO,
    meta: {
      isOnlyUser: true,
      menuId: 'myInfo',
    },
    component: () => import('@/pages/user/myinfo/AccountEdit.vue'),
  },
  {
    path: ligRouterPath.HOME,
    meta: {
      menuId: menuId.SYSTEM_NOTICE_READ,
    },
    component: () => import('@/pages/shared/notice/NoticeList.vue'),
  },
  {
    path: ligRouterPath.INFO, // 기본정보
    meta: {
      menuId: menuId.ITEM_READ,
    },
    component: () => import('@/components/layout/EmptyRouterView.vue'),
    children: [
      {
        path: ligRouterPath.ITEM, // 품목관리
        meta: {
          menuId: menuId.ITEM_READ,
        },
        component: () => import('@/components/layout/EmptyRouterView.vue'),
        redirect: ligRouterPath.ITEM_LIST,
        children: [
          {
            path: ligRouterPath.ITEM_LIST,
            meta: {
              menuId: menuId.ITEM_READ,
            },
            component: () => import('@/pages/admin/item/ItemList.vue'),
          },
          {
            path: `${ligRouterPath.ITEM_VIEW}/:id`,
            meta: {
              menuId: menuId.ITEM_READ,
            },
            component: () => import('@/pages/admin/item/ItemView.vue'),
          },
          {
            path: `${ligRouterPath.ITEM_WRITE}`,

            meta: {
              menuId: menuId.ITEM_WRITE,
            },
            component: () => import('@/pages/admin/item/ItemWrite.vue'),
          },
          {
            path: `${ligRouterPath.ITEM_MODIFY}/:id`,

            meta: {
              menuId: menuId.ITEM_WRITE,
              isModifyMode: true,
            },
            component: () => import('@/pages/admin/item/ItemWrite.vue'),
          },
        ],
      },
      {
        path: ligRouterPath.PARTNER, // 파트너관리
        meta: {
          menuId: menuId.PARTNER_READ,
        },
        redirect: ligRouterPath.PARTNER_LIST,
        component: () => import('@/components/layout/EmptyRouterView.vue'),
        children: [
          {
            path: ligRouterPath.PARTNER_LIST,
            meta: {
              menuId: menuId.PARTNER_READ,
            },
            component: () => import('@/pages/admin/partner/PartnerList.vue'),
          },
          {
            path: `${ligRouterPath.PARTNER_VIEW}/:id`,
            meta: {
              menuId: menuId.PARTNER_READ,
            },
            component: () => import('@/pages/admin/partner/PartnerView.vue'),
          },
        ],
      },
      {
        path: ligRouterPath.PERFORMANCE, // 수행평가도관리
        meta: {
          menuId: menuId.PERFORMANCE_READ,
        },
        component: () => import('@/components/layout/EmptyRouterView.vue'),
        redirect: ligRouterPath.PERFORMANCE_LIST,
        children: [
          {
            path: ligRouterPath.PERFORMANCE_LIST,
            meta: {
              menuId: menuId.PERFORMANCE_READ,
            },
            component: () => import('@/pages/admin/performance/PerformanceList.vue'),
          },
          {
            path: `${ligRouterPath.PERFORMANCE_VIEW}/:id`,
            meta: {
              menuId: menuId.PERFORMANCE_READ,
            },
            component: () => import('@/pages/admin/performance/PerformanceView.vue'),
          },
          {
            path: `${ligRouterPath.PERFORMANCE_WRITE}/:id/:inspectId`,
            meta: {
              menuId: menuId.PERFORMANCE_WRITE,
            },
            component: () => import('@/pages/admin/performance/PerformanceWrite.vue'),
          },
          {
            path: `${ligRouterPath.PERFORMANCE_MODIFY}/:id`,
            meta: {
              menuId: menuId.PERFORMANCE_WRITE,
              isModifyMode: true,
            },
            component: () => import('@/pages/admin/performance/PerformanceWrite.vue'),
          },
        ],
      },
    ],
  },
  {
    path: ligRouterPath.PURCHASE_LIST, // 구매관리
    meta: {
      menuId: menuId.PURCHASE_READ,
    },
    component: () => import('@/components/layout/EmptyRouterView.vue'),
    redirect: ligRouterPath.PURCHASE_LIST,
    children: [
      {
        path: ligRouterPath.PURCHASE_LIST, // 구매요청
        meta: {
          menuId: menuId.PURCHASE_READ,
        },
        component: () => import('@/components/layout/EmptyRouterView.vue'),
        children: [
          {
            path: ligRouterPath.PURCHASE_LIST,
            meta: {
              menuId: menuId.PURCHASE_READ,
            },
            component: () => import('@/pages/admin/purchase/PurchaseList.vue'),
          },
          {
            path: `${ligRouterPath.PURCHASE_VIEW}/:id`,
            meta: {
              menuId: menuId.PURCHASE_READ,
            },
            component: () => import('@/pages/admin/purchase/PurchaseView.vue'),
          },
          {
            path: ligRouterPath.PURCHASE_WRITE,
            name: ligRouterPath.PURCHASE_WRITE,
            meta: {
              menuId: menuId.PURCHASE_WRITE,
            },
            component: () => import('@/pages/admin/purchase/PurchaseWrite.vue'),
          },
          {
            path: `${ligRouterPath.PURCHASE_MODIFY}/:id`,
            meta: {
              menuId: menuId.PURCHASE_WRITE,
              isModifyMode: true,
            },
            component: () => import('@/pages/admin/purchase/PurchaseWrite.vue'),
          },
        ],
      },
      {
        path: ligRouterPath.ESTIMATE_LIST, // 견적관리
        meta: {
          menuId: menuId.ESTIMATE_READ,
        },
        component: () => import('@/components/layout/EmptyRouterView.vue'),
        children: [
          {
            path: ligRouterPath.ESTIMATE_LIST,
            meta: {
              menuId: menuId.ESTIMATE_READ,
            },
            component: () => import('@/pages/shared/estimate/EstimateList'),
          },
          {
            path: `${ligRouterPath.ESTIMATE_VIEW}/:id`,
            meta: {
              menuId: menuId.ESTIMATE_READ,
            },
            component: () => import('@/pages/shared/estimate/EstimateView'),
          },
          {
            path: `${ligRouterPath.ESTIMATE_WRITE}`,
            name: `${ligRouterPath.ESTIMATE_WRITE}`,
            meta: {
              menuId: menuId.ESTIMATE_WRITE,
              isOnlyAdmin: true,
            },
            component: () => import('@/pages/admin/estimate/EstimateWrite'),
          },
          {
            path: `${ligRouterPath.ESTIMATE_WRITE}/:id`,
            meta: {
              menuId: menuId.ESTIMATE_WRITE,
              isOnlyUser: true,
            },
            component: () => import('@/pages/user/estimate/EstimateWrite'),
          },
          {
            path: `${ligRouterPath.ESTIMATE_MODIFY}/:id`,
            meta: {
              menuId: menuId.ESTIMATE_WRITE,

              isModifyMode: true,
            },
            component: () => import('@/pages/admin/estimate/EstimateWrite'),
          },
        ],
      },
      {
        path: ligRouterPath.ANNOUNCE, // 공고관리
        meta: {
          menuId: menuId.ANNOUNCE_READ,
        },
        component: () => import('@/components/layout/EmptyRouterView'),
        redirect: ligRouterPath.ANNOUNCE_LIST,
        children: [
          {
            path: ligRouterPath.ANNOUNCE_LIST,
            meta: {
              menuId: menuId.ANNOUNCE_READ,
            },
            component: () => import('@/pages/shared/announce/AnnounceList'),
          },
          {
            path: `${ligRouterPath.ANNOUNCE_VIEW}/:id`,
            meta: {
              menuId: menuId.ANNOUNCE_READ,
            },
            component: () => import('@/pages/shared/announce/AnnounceView'),
          },
          {
            path: `${ligRouterPath.ANNOUNCE_WRITE}/:id`,
            name: `${ligRouterPath.ANNOUNCE_WRITE}`,
            meta: {
              menuId: menuId.ANNOUNCE_WRITE,
            },
            component: () => import('@/pages/admin/announce/AnnounceWrite'),
          },
          {
            path: `${ligRouterPath.ANNOUNCE_MODIFY}/:id`,
            meta: {
              menuId: menuId.ANNOUNCE_WRITE,
              isModifyMode: true,
            },
            component: () => import('@/pages/admin/announce/AnnounceWrite'),
          },
        ],
      },
      {
        path: ligRouterPath.BID, // 입찰관리
        meta: {
          menuId: menuId.BID_READ,
        },
        component: () => import('@/components/layout/EmptyRouterView.vue'),
        redirect: ligRouterPath.BID_LIST,
        children: [
          {
            path: ligRouterPath.BID_LIST,
            meta: {
              menuId: menuId.BID_READ,
            },
            component: () => import('@/pages/shared/bid/BidList.vue'),
          },
          {
            path: `${ligRouterPath.BID_VIEW}/:id`,
            meta: {
              menuId: menuId.BID_READ,
            },
            component: () => import('@/pages/shared/bid/BidView.vue'),
          },
          {
            path: `${ligRouterPath.BID_WRITE}/:id`,
            meta: {
              menuId: menuId.BID_WRITE,
            },
            component: () => import('@/pages/user/bid/BidWrite.vue'),
          },
        ],
      },
      {
        path: ligRouterPath.DRAFT, // 기안관리
        meta: {
          menuId: menuId.DRAFT_READ,
        },
        component: () => import('@/components/layout/EmptyRouterView.vue'),
        redirect: ligRouterPath.DRAFT_LIST,
        children: [
          {
            path: ligRouterPath.DRAFT_LIST,
            meta: {
              menuId: menuId.DRAFT_READ,
            },

            component: () => import('@/pages/admin/draft/DraftList'),
          },
          {
            path: `${ligRouterPath.DRAFT_VIEW}/:id`,
            meta: {
              menuId: menuId.DRAFT_READ,
            },
            component: () => import('@/pages/admin/draft/DraftView'),
          },
          {
            path: `${ligRouterPath.DRAFT_WRITE}`,
            name: `${ligRouterPath.DRAFT_WRITE}`,
            meta: {
              menuId: menuId.DRAFT_WRITE,
            },
            component: () => import('@/pages/admin/draft/DraftWrite'),
          },
          {
            path: `${ligRouterPath.DRAFT_MODIFY}/:id`,
            meta: {
              menuId: menuId.DRAFT_WRITE,
              isModifyMode: true,
            },
            component: () => import('@/pages/admin/draft/DraftWrite'),
          },
        ],
      },
    ],
  },
  {
    path: ligRouterPath.CONTRACT, // 발주/계약
    meta: {
      menuId: menuId.CONTRACT_READ,
    },
    component: () => import('@/components/layout/EmptyRouterView.vue'),
    redirect: ligRouterPath.CONTRACT_LIST,
    children: [
      {
        path: ligRouterPath.CONTRACT, // 계약
        meta: {
          menuId: menuId.CONTRACT_READ,
        },
        component: () => import('@/components/layout/EmptyRouterView.vue'),
        redirect: ligRouterPath.CONTRACT_LIST,
        children: [
          {
            path: ligRouterPath.CONTRACT_LIST,
            meta: {
              menuId: menuId.CONTRACT_READ,
            },
            component: () => import('@/pages/shared/contract/ContractList.vue'),
          },
          {
            path: `${ligRouterPath.CONTRACT_VIEW}/:id`,
            meta: {
              menuId: menuId.CONTRACT_READ,
            },
            component: () => import('@/pages/shared/contract/ContractView.vue'),
          },
          {
            path: `${ligRouterPath.CONTRACT_WRITE}`,
            name: `${ligRouterPath.CONTRACT_WRITE}`,
            meta: {
              menuId: menuId.CONTRACT_WRITE,
            },
            component: () => import('@/pages/admin/contract/ContractInfoWrite.vue'),
          },
          {
            path: `${ligRouterPath.CONTRACT_MODIFY}/:id`,
            name: `${ligRouterPath.CONTRACT_MODIFY}`,
            meta: {
              menuId: menuId.CONTRACT_WRITE,
              isModifyMode: true,
            },
            component: () => import('@/pages/admin/contract/ContractInfoWrite.vue'),
          },
          {
            path: `${ligRouterPath.CONTRACT_RENEWAL}`, //변경계약
            name: `${ligRouterPath.CONTRACT_RENEWAL}`,
            meta: {
              menuId: menuId.CONTRACT_WRITE,
              isModifyMode: true,
              isRenewalMode: true,
            },
            component: () => import('@/pages/admin/contract/ContractInfoWrite.vue'),
          },
        ],
      },
      {
        path: ligRouterPath.ORDER, // 발주
        meta: {
          menuId: menuId.ORDER_READ,
        },
        component: () => import('@/components/layout/EmptyRouterView.vue'),
        redirect: ligRouterPath.ORDER_LIST,
        children: [
          {
            path: ligRouterPath.ORDER_LIST,
            meta: {
              menuId: menuId.ORDER_READ,
            },
            component: () => import('@/pages/shared/order/OrderList.vue'),
          },
          {
            path: `${ligRouterPath.ORDER_VIEW}/:id`,
            meta: {
              menuId: menuId.ORDER_READ,
            },
            component: () => import('@/pages/shared/order/OrderView.vue'),
          },
          {
            path: `${ligRouterPath.ORDER_WRITE}/:id`,
            name: `${ligRouterPath.ORDER_WRITE}`,
            meta: {
              menuId: menuId.ORDER_WRITE,
            },
            component: () => import('@/pages/admin/order/OrderWrite.vue'),
          },
          {
            path: `${ligRouterPath.ORDER_MODIFY}/:id`,
            meta: {
              menuId: menuId.ORDER_WRITE,

              isModifyMode: true,
            },
            component: () => import('@/pages/admin/order/OrderWrite.vue'),
          },
          {
            path: `${ligRouterPath.ORDER_RENEWAL}`, //변경발주
            name: `${ligRouterPath.ORDER_RENEWAL}`,
            meta: {
              menuId: menuId.ORDER_WRITE,
              isModifyMode: true,
              isRenewalMode: true,
            },
            component: () => import('@/pages/admin/order/OrderWrite.vue'),
          },
        ],
      },
    ],
  },
  {
    path: ligRouterPath.INSPECT, // 마감/정산
    meta: {
      menuId: menuId.INSPECT_READ,
    },
    component: () => import('@/components/layout/EmptyRouterView.vue'),
    redirect: ligRouterPath.INSPECT_LIST,
    children: [
      {
        path: ligRouterPath.INSPECT,
        meta: {
          menuId: menuId.INSPECT_READ,
        },
        component: () => import('@/components/layout/EmptyRouterView.vue'),
        redirect: ligRouterPath.INSPECT_LIST,
        children: [
          {
            path: ligRouterPath.INSPECT_LIST,
            meta: {
              menuId: menuId.INSPECT_READ,
            },
            component: () => import('@/pages/shared/inspect/InspectList.vue'),
          },
          {
            path: `${ligRouterPath.INSPECT_VIEW}/:refPk`,
            meta: {
              menuId: menuId.INSPECT_READ,
            },
            component: () => import('@/pages/shared/inspect/InspectView.vue'),
          },

          {
            path: `${ligRouterPath.INSPECT_WRITE}/:refPk`,
            meta: {
              menuId: menuId.INSPECT_WRITE,
              isRefPk: true,
            },
            component: () => import('@/pages/shared/inspect/InspectWrite.vue'),
          },
        ],
      },
      {
        path: ligRouterPath.TRANSACTIONREPORT,
        meta: {
          menuId: menuId.TRANSACTIONREPORT_READ,
        },
        component: () => import('@/components/layout/EmptyRouterView.vue'),
        redirect: ligRouterPath.TRANSACTIONREPORT_LIST,
        children: [
          {
            path: ligRouterPath.TRANSACTIONREPORT_LIST,
            meta: {
              menuId: menuId.TRANSACTIONREPORT_READ,
            },
            component: () => import('@/pages/shared/transactionReport/TransactionReportList.vue'),
          },
          {
            path: `${ligRouterPath.TRANSACTIONREPORT_VIEW}/:id`,
            meta: {
              menuId: menuId.TRANSACTIONREPORT_READ,
            },
            component: () => import('@/pages/shared/transactionReport/TransactionReportView.vue'),
          },
        ],
      },
      {
        path: ligRouterPath.STATEMENT,
        meta: {
          menuId: menuId.STATEMENT_READ,
        },
        component: () => import('@/components/layout/EmptyRouterView.vue'),
        redirect: ligRouterPath.STATEMENT_LIST,
      },
    ],
  },
  ...paymentRouter,

  {
    path: ligRouterPath.STATISTICS, // 통계
    meta: {
      menuId: menuId.STATISTICS,
    },
    redirect: ligRouterPath.STATISTICS_ORDERLIST,
    component: () => import('@/components/layout/EmptyRouterView.vue'),
    children: [
      {
        path: ligRouterPath.STATISTICS_ORDERLIST, // 발주현황
        meta: {
          menuId: menuId.STATISTICS_ORDERLIST,
        },
        component: () => import('@LIG/pages/admin/statistics/list/OrderList.vue'),
      },
      {
        path: ligRouterPath.STATISTICS_ORDERCASE, // 발주건별현황
        meta: {
          menuId: menuId.STATISTICS_ORDERCASE,
        },
        component: () => import('@LIG/pages/admin/statistics/list/OrderCase.vue'),
      },
    ],
  },

  {
    path: '/system', //  ligRouterPath.SYSTEM, // 시스템관리
    meta: {
      menuId: menuId.SYSTEM,
    },
    component: () => import('@/components/layout/EmptyRouterView.vue'),

    children: [
      {
        path: ligRouterPath.SYSTEM_CODE, // 코드관리
        meta: {
          menuId: menuId.SYSTEM_CODE_READ,
        },
        component: () => import('@/components/layout/EmptyRouterView.vue'),
        redirect: ligRouterPath.SYSTEM_CODE_LIST,
        children: [
          {
            path: ligRouterPath.SYSTEM_CODE_LIST,
            meta: {
              menuId: menuId.SYSTEM_CODE_READ,
            },
            component: () => import('@/pages/Dummy.vue'),
          },
          {
            path: ligRouterPath.SYSTEM_CODE_WRITE,
            meta: {
              menuId: menuId.SYSTEM_CODE_WRITE,
            },
            component: () => import('@/pages/Dummy.vue'),
          },
        ],
      },
      {
        path: ligRouterPath.SYSTEM_HANDLED, // 취급품목
        meta: {
          menuId: menuId.SYSTEM_HANDLED,
        },
        component: () => import('@/pages/admin/system/handle/Handle.vue'),
      },
      {
        path: ligRouterPath.SYSTEM_CATEGORY, // 카테고리
        meta: {
          menuId: menuId.SYSTEM_CATEGORY,
        },

        component: () => import('@/pages/admin/system/category/CategoryManagement.vue'),
      },
      {
        path: ligRouterPath.SYSTEM_TEMPLATE, // 템플릿관리리
        meta: {
          menuId: menuId.SYSTEM_TEMPLATE_READ,
        },
        component: () => import('@/components/layout/EmptyRouterView.vue'),
        redirect: ligRouterPath.SYSTEM_TEMPLATE_LIST,
        children: [
          {
            path: ligRouterPath.SYSTEM_TEMPLATE_LIST,
            meta: {
              menuId: menuId.SYSTEM_TEMPLATE_READ,
            },
            component: () => import('@/pages/admin/system/template/TemplateList.vue'),
          },
          {
            path: ligRouterPath.SYSTEM_TEMPLATE_WRITE,
            meta: {
              menuId: menuId.SYSTEM_TEMPLATE_WRITE,
            },
            component: () => import('@/pages/admin/system/template/TemplateWrite.vue'),
          },
          {
            path: `${ligRouterPath.SYSTEM_TEMPLATE_MODIFY}`,
            name: `${ligRouterPath.SYSTEM_TEMPLATE_MODIFY}`,
            meta: {
              menuId: menuId.SYSTEM_TEMPLATE_WRITE,
              isModifyMode: true,
            },
            component: () => import('@/pages/admin/system/template/TemplateModify.vue'),
          },
        ],
      },
      {
        path: ligRouterPath.SYSTEM_PERFORMANCE, // 업체(평가)관리
        meta: {
          menuId: menuId.SYSTEM_PERFORMANCE_READ,
        },
        component: () => import('@/components/layout/EmptyRouterView.vue'),
        children: [
          {
            path: ligRouterPath.SYSTEM_PERFORMANCE_SETTING,
            meta: {
              menuId: menuId.SYSTEM_PERFORMANCE_READ,
            },
            component: () =>
              import('@/pages/admin/system/performance/SystemPerformanceSettingList.vue'),
          },
          {
            path: ligRouterPath.SYSTEM_PERFORMANCE_WRITE,
            meta: {
              menuId: menuId.SYSTEM_PERFORMANCE_WRITE,
            },
            component: () => import('@/pages/admin/system/performance/SystemPerformanceWrite.vue'),
          },
          {
            path: `${ligRouterPath.SYSTEM_PERFORMANCE_MODIFY}/:id`,
            meta: {
              menuId: menuId.SYSTEM_PERFORMANCE_WRITE,
              isModifyMode: true,
            },
            component: () => import('@/pages/admin/system/performance/SystemPerformanceWrite.vue'),
          },
          {
            path: `${ligRouterPath.SYSTEM_PERFORMANCE_SETTING}/:id`,
            meta: {
              menuId: menuId.SYSTEM_PERFORMANCE_READ,
            },
            component: () => import('@/pages/admin/system/performance/SystemPerformanceView.vue'),
          },
          {
            path: ligRouterPath.SYSTEM_PERFORMANCE_PRESENT,
            meta: {
              menuId: menuId.SYSTEM_PERFORMANCE_READ,
            },
            component: () =>
              import('@/pages/admin/system/performance/SystemPerformancePresentList.vue'),
          },
          {
            path: ligRouterPath.SYSTEM_PERFORMANCE_RESULT,
            meta: {
              menuId: menuId.SYSTEM_PERFORMANCE_READ,
            },
            component: () =>
              import('@/pages/admin/system/performance/SystemPerformanceResultList.vue'),
          },
        ],
      },
      {
        path: ligRouterPath.SYSTEM_DIRECTOR, // 담당자관리
        meta: {
          menuId: menuId.SYSTEM_DIRECTOR_READ,
        },
        component: () => import('@/components/layout/EmptyRouterView.vue'),
        redirect: ligRouterPath.SYSTEM_DIRECTOR_LIST,
        children: [
          {
            path: ligRouterPath.SYSTEM_DIRECTOR_LIST,
            meta: {
              menuId: menuId.SYSTEM_DIRECTOR_READ,
            },

            component: () => import('@/pages/admin/system/director/DirectorList.vue'),
          },
          {
            path: ligRouterPath.SYSTEM_DIRECTOR_WRITE,
            meta: {
              menuId: menuId.SYSTEM_DIRECTOR_WRITE,
            },
            component: () => import('@/pages/admin/system/director/DirectorWrite.vue'),
          },
          {
            path: `${ligRouterPath.SYSTEM_DIRECTOR_MODIFY}/:id`,
            meta: {
              menuId: menuId.SYSTEM_DIRECTOR_WRITE,
              isModifyMode: true,
            },
            component: () => import('@/pages/admin/system/director/DirectorModify.vue'),
          },
        ],
      },

      {
        path: ligRouterPath.SYSTEM_NOTICE, // 공지사항
        meta: {
          menuId: menuId.SYSTEM_NOTICE_READ,
        },
        component: () => import('@/components/layout/EmptyRouterView.vue'),
        redirect: ligRouterPath.SYSTEM_NOTICE_LIST,
        children: [
          {
            path: ligRouterPath.SYSTEM_NOTICE_LIST,
            meta: {
              menuId: menuId.SYSTEM_NOTICE_READ,
            },
            component: () => import('@/pages/shared/notice/NoticeList.vue'),
          },
          {
            path: `${ligRouterPath.SYSTEM_NOTICE_VIEW}/:id`,
            meta: {
              menuId: menuId.SYSTEM_NOTICE_READ,
            },
            component: () => import('@/pages/shared/notice/NoticeView.vue'),
          },
          {
            path: `${ligRouterPath.SYSTEM_NOTICE_WRITE}`,
            meta: {
              menuId: menuId.SYSTEM_NOTICE_WRITE,
            },
            component: () => import('@/pages/admin/system/notice/NoticeWrite.vue'),
          },
          {
            path: `${ligRouterPath.SYSTEM_NOTICE_MODIFY}/:id`,
            meta: {
              menuId: menuId.SYSTEM_NOTICE_WRITE,
              isModifyMode: true,
            },
            component: () => import('@/pages/admin/system/notice/NoticeWrite.vue'),
          },
        ],
      },
      {
        path: `/tsttoolkit`, // 테스트
        meta: {
          menuId: menuId.SYSTEM_NOTICE_WRITE,
        },
        component: () => import('@LIG/_views/tstToolKit/tstToolKit.vue'),
      },
      {
        path: `/templatePreview`, //
        name: `/templatePreview`,
        meta: {
          menuId: menuId.CONTRACT_WRITE,
        },
        component: () => import('@/components/shared/template/TemplatePreviewWindow.vue'),
      },
    ],
  },

  { path: '*', redirect: ligRouterPath.HOME },
];

const ligRouter = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (from.path === to.path) {
      return;
    }

    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
  routes,
});

const token = LocalStorageManager.shared.getTokenData();

ligRouter.beforeEach(async (to, from, next) => {
  store.dispatch(ALERT_ACTION.HIDE_ALERT_YN);
  store.dispatch(ALERT_ACTION.HIDE_ALERT);

  store.dispatch(PROGRESS_END_ACTION);

  const query = to.query;
  if (query.token) {
    sessionStorage.setItem('userData', decodeURIComponent(query.token));
    next({
      path: ligRouterPath.HOME,
    });
    return;
  }

  const userData = LocalStorageManager.shared.getUserData();

  const isRequiresAuth = to.matched.some((record) => record.meta.requiresAuth !== false);

  const { isOnlyAdmin, isOnlyUser } = to.meta;

  if (!userData) {
    if (isRequiresAuth) {
      if (process.env.VUE_APP_ADMIN_WEB_ENDPOINT.indexOf(window.location.host) > -1) {
        if (process.env.NODE_ENV === 'local.lig') {
          next({
            path: ligRouterPath.LOGIN_ADMIN,
            query: null,
          });
        } else {
          next({
            path: ligRouterPath.LOGIN,
            query: null,
          });
        }
      } else {
        next({
          path: ligRouterPath.LOGIN,
          query: null,
        });
      }
    } else {
      next();
    }

    return;
  }

  const isReviewStatus = LocalStorageManager.shared.getIsReviewStatus();
  if (isReviewStatus && to.path !== ligRouterPath.MY_INFO) {
    next({
      path: ligRouterPath.MY_INFO,
    });
    return;
  }

  const isAdmin = LocalStorageManager.shared.getIsAdmin();
  const hasAdmin = LocalStorageManager.shared.hasAdmin();
  const isPartner = LocalStorageManager.shared.getIsPartner();

  if ((isAdmin && isOnlyUser) || (!isAdmin && isOnlyAdmin)) {
    next({
      path: ligRouterPath.HOME,
    });
    return;
  }

  const cantGoPageWithUserData =
    to.path === ligRouterPath.JOIN ||
    to.path === ligRouterPath.JOIN_INQUIRY ||
    to.path === ligRouterPath.LOGIN ||
    to.path === ligRouterPath.LOGIN_ADMIN;
  if (userData && cantGoPageWithUserData) {
    next({
      path: ligRouterPath.HOME,
    });
    return;
  }

  if (!isPartner) {
    if (to.path === ligRouterPath.HOME) {
      if (from.path !== ligRouterPath.SYSTEM_NOTICE_LIST) {
        next({
          path: ligRouterPath.SYSTEM_NOTICE_LIST,
        });
      } else {
        next(false);
        document.location = '/';
      }
      return;
    }
  }
  if (isPartner) {
    // 파트너

    if (to.path === ligRouterPath.HOME) {
      if (from.path !== ligRouterPath.SYSTEM_NOTICE_LIST) {
        await ligRouter.push(ligRouterPath.SYSTEM_NOTICE_LIST);
      } else {
        document.location = '/';
      }

      return;
    }
  }
  const isInspect = LocalStorageManager.shared.getIsInspect();
  const isUser = LocalStorageManager.shared.getIsUser();
  const isSuper = LocalStorageManager.shared.getIsSuper();
  if (!!userData) {
    if (isPartner) {
      await store.dispatch(MENU_ROLE_PARTNER_ACTION);
    } else if (isSuper) {
      await store.dispatch(MENU_ROLE_SUPER_ACTION);
    } else if (isAdmin || isInspect || isUser) {
      await store.dispatch(MENU_ROLE_ACTION);
    }
  }

  const { menuId } = to.meta;

  const isValidMenu = getIsValidMenu(menuId);

  if (!isValidMenu) {
    if (menuId === 'myInfo') {
      next();
      return;
    }
    console.error('권한이 없습니다.', to);
    console.error('권한이 없습니다.', from);
    console.error('권한이 없습니다.', from.path ? from.path : ligRouterPath.HOME);

    if (to.path === ligRouterPath.HOME) {
      alert('접근 권한이 없습니다.');
      next(false);
    } else {
      ligRouter
        .push({
          path: from.path ? from.path : ligRouterPath.HOME,
        })
        .catch((e) => {
          console.error(e);
          alert('접근 권한이 없습니다.');
        });
    }

    return;
  }
  next();
  //}
});

const getIsValidMenu = (menuId, _menus) => {
  const roleList = store.getters['getRoleList'];

  return roleList.findIndex((item) => item === menuId) > -1;
};

export default ligRouter;
