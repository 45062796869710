import ApiService from '@/services/ApiService';
import apiPath from '@/services/apiPath';
import {
  GET_ORDERLIST_STATUS_ACTION,
  GET_ORDERLIST_KEYWORD_ACTION,
  GET_ORDERCASE_STATUS_ACTION,
  GET_ORDERCASE_KEYWORD_ACTION,
  GET_ORDERLIST_LIST_ACTION,
  GET_ORDERCASE_LIST_ACTION,
} from './action';
import {
  ORDERLIST_STATUS_MUTATION,
  ORDERLIST_KEYWORD_MUTATION,
  ORDERCASE_STATUS_MUTATION,
  ORDERCASE_KEYWORD_MUTATION,
} from './mutation';

export default {
  state: {
    orderListStatuslist: [],
    orderListKeywordlist: [],
    orderCaseStatuslist: [],
    orderCaseKeywordlist: [],
  },
  actions: {
    async [GET_ORDERLIST_STATUS_ACTION](context) {
      if (context.state.orderListStatuslist.length > 0) return; // 한번만 호출.
      // const result = await ApiService.shared.getData(`${apiPath.INSPECT}/status`); //API가 없어서 front에서 하드코딩함
      const result = {
        code: '',
        data: [
          { id: '', code: '', desc: '전체' },
          { id: 'PURCHASE_COMPLETE', code: 'PURCHASE_COMPLETE', desc: '접수완료' },
          { id: 'ORDER_COMPLETE', code: 'ORDER_COMPLETE', desc: '발주완료' },
          { id: 'INSPECT_COMPLETE', code: 'INSPECT_COMPLETE', desc: '검수완료' },
          { id: 'TAXINVOICE_SUCCESS', code: 'TAXINVOICE_SUCCESS', desc: '세금계산서 발행' },
          { id: 'TAXINVOICE_COMPLETE', code: 'TAXINVOICE_COMPLETE', desc: '세금계산서 완료' },
        ],
        text: '',
      };
      const { code, data, text } = result;
      this.commit({
        type: ORDERLIST_STATUS_MUTATION,
        list: data || [],
      });
    },
    async [GET_ORDERLIST_KEYWORD_ACTION](context) {
      if (context.state.orderListKeywordlist.length > 0) return; // 한번만 호출.
      // const result = await ApiService.shared.getData(`${apiPath.INSPECT}/status`); //API가 없어서 front에서 하드코딩함

      const result = {
        code: '',
        data: [
          { id: 'projectName', code: 'projectName', desc: '프로젝트명' },
          { id: 'companyName', code: 'companyName', desc: '파트너사' },
          { id: 'orderTitle', code: 'orderTitle', desc: '발주서명' },
          { id: 'orderCid', code: 'orderCid', desc: '발주번호' },
          { id: 'requestTitle', code: 'requestTitle', desc: '구매요청명' },
          { id: 'requestCid', code: 'requestCid', desc: '구매요청번호' },
          { id: 'itemName', code: 'itemName', desc: '품목명' },
          { id: 'request', code: 'request', desc: '구매요청자' },
          { id: 'purchase', code: 'purchase', desc: '구매담당자' },
        ],
        text: '',
      };
      const { code, data, text } = result;
      this.commit({
        type: ORDERLIST_KEYWORD_MUTATION,
        list: data || [],
      });
    },

    async [GET_ORDERCASE_STATUS_ACTION](context) {
      if (context.state.orderCaseStatuslist.length > 0) return; // 한번만 호출.
      // const result = await ApiService.shared.getData(`${apiPath.INSPECT}/status`); //API가 없어서 front에서 하드코딩함
      const result = {
        code: '',
        data: [
          { id: '', code: '', desc: '전체' },
          { id: 'T', code: 'T', desc: '발주서 작성중' },
          { id: 'C', code: 'C', desc: '발행완료' },
          { id: 'E', code: 'E', desc: '발주취소' },
          { id: 'M', code: 'M', desc: '중도해지' },
          // { id: 'D', code: 'D', desc: '삭제' },
        ],
        text: '',
      };
      const { code, data, text } = result;
      this.commit({
        type: ORDERCASE_STATUS_MUTATION,
        list: data || [],
      });
    },
    async [GET_ORDERCASE_KEYWORD_ACTION](context) {
      if (context.state.orderCaseKeywordlist.length > 0) return; // 한번만 호출.
      // const result = await ApiService.shared.getData(`${apiPath.INSPECT}/status`); //API가 없어서 front에서 하드코딩함
      const result = {
        code: '',
        data: [
          { id: 'projectName', code: 'projectName', desc: '프로젝트명' },
          { id: 'companyName', code: 'companyName', desc: '파트너사' },
          { id: 'orderTitle', code: 'orderTitle', desc: '발주서명' },
          { id: 'orderCid', code: 'orderCid', desc: '발주번호' },
          { id: 'inspect', code: 'inspect', desc: '검수담당자' },
          { id: 'purchase', code: 'purchase', desc: '구매담당자' },
        ],
        text: '',
      };
      const { code, data, text } = result;
      this.commit({
        type: ORDERCASE_KEYWORD_MUTATION,
        list: data || [],
      });
    },

    async [GET_ORDERLIST_LIST_ACTION](context) {
      await context.dispatch(GET_ORDERLIST_STATUS_ACTION);
      await context.dispatch(GET_ORDERLIST_KEYWORD_ACTION);
    },
    async [GET_ORDERCASE_LIST_ACTION](context) {
      await context.dispatch(GET_ORDERCASE_STATUS_ACTION);
      await context.dispatch(GET_ORDERCASE_KEYWORD_ACTION);
    },
  },

  mutations: {
    [ORDERLIST_STATUS_MUTATION](state, payload) {
      // code 로 들어오는 값을 기준 값으로 (value)
      const { list } = payload;
      state.orderListStatuslist = Object.freeze(
        list.map((item, index) => Object.freeze({ ...item, index: index })),
      );
    },
    [ORDERLIST_KEYWORD_MUTATION](state, payload) {
      // code 로 들어오는 값을 기준 값으로 (value)
      const { list } = payload;
      state.orderListKeywordlist = Object.freeze(
        list.map((item, index) => Object.freeze({ ...item, index: index })),
      );
    },
    [ORDERCASE_STATUS_MUTATION](state, payload) {
      // code 로 들어오는 값을 기준 값으로 (value)
      const { list } = payload;
      state.orderCaseStatuslist = Object.freeze(
        list.map((item, index) => Object.freeze({ ...item, index: index })),
      );
    },
    [ORDERCASE_KEYWORD_MUTATION](state, payload) {
      // code 로 들어오는 값을 기준 값으로 (value)
      const { list } = payload;
      state.orderCaseKeywordlist = Object.freeze(
        list.map((item, index) => Object.freeze({ ...item, index: index })),
      );
    },
  },
};
