export const TOGGLE_STATEMENT_SIDE_MENU = "TOGGLE_STATEMENT_SIDE_MENU";
export const OPEN_STATEMENT_SIDE_MENU = "OPEN_STATEMENT_SIDE_MENU";

export const PROGRESS_START_ACTION = "PROGRESS_START_ACTION";
export const PROGRESS_END_ACTION = "PROGRESS_END_ACTION";

//신규
export const GET_STATEMENT_STATUS_LIST = "GET_STATEMENT_STATUS_LIST";
export const STATEMENT_LIST_ACTION = "STATEMENT_LIST_ACTION";
export const STATEMENT_WRITE_ACTION = "STATEMENT_WRITE_ACTION";
