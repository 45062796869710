export const CONTRACT_STATUS_LIST_MUTATION = "CONTRACT_STATUS_LIST_MUTATION";
export const CONTRACT_TYPE_LIST_MUTATION = "CONTRACT_TYPE_LIST_MUTATION";
export const CONTRACT_PART_LIST_MUTATION = "CONTRACT_PART_LIST_MUTATION";
export const CONTRACT_REDUCE_LIST_MUTATION = "CONTRACT_REDUCE_LIST_MUTATION";
export const CONTRACT_KEYWORD_LIST_MUTATION = "CONTRACT_KEYWORD_LIST_MUTATION";

export const CONTRACT_COMPANY_CODE_LIST_MUTATION = "CONTRACT_COMPANY_CODE_LIST_MUTATION";

export const CONTRACT_CALCULATE_RANGE_LIST_MUTATION = "CONTRACT_CALCULATE_RANGE_LIST_MUTATION";
export const CONTRACT_CALCULATE_TYPE_LIST_MUTATION = "CONTRACT_CALCULATE_TYPE_LIST_MUTATION";
export const CONTRACT_PARTNER_INSPECT_TYPE_LIST_MUTATION =
  "CONTRACT_PARTNER_INSPECT_TYPE_LIST_MUTATION";
export const CONTRACT_CONDITION_TYPE_LIST_MUTATION = "CONTRACT_CONDITION_TYPE_LIST_MUTATION";
