import { GET_DRAFT_STATUS_LIST, DRAFT_LIST_ACTION, DRAFT_WRITE_ACTION } from "./action";
import { DRAFT_STATUS_LIST_MUTATION } from "./mutation";
import ApiService from "@/services/ApiService";
import apiPath from "@/services/apiPath";

import { CATEGORY_ACTION } from "../category/action";

export default {
  state: {
    statusList: [],
  },
  actions: {
    async [GET_DRAFT_STATUS_LIST](context) {
      if (context.state.statusList.length > 0) return; // 한번만 호출.

      const result = await ApiService.shared.getData(apiPath.DRAFT_STATUS_LIST);

      this.commit({
        type: DRAFT_STATUS_LIST_MUTATION,
        items: result.data || [],
      });
    },
    async [DRAFT_LIST_ACTION](context) {
      await context.dispatch(GET_DRAFT_STATUS_LIST);

      await context.dispatch(CATEGORY_ACTION.GET_LIST_0);
    },
    async [DRAFT_WRITE_ACTION](context) {
      await context.dispatch(CATEGORY_ACTION.GET_LIST_0);
    },
  },
  mutations: {
    [DRAFT_STATUS_LIST_MUTATION](state, payload) {
      // code 로 들어오는 값을 기준 값으로 (value)
      const arr = payload.items;

      arr.forEach((element) => {
        element.value = element.code;
      });

      state.statusList = arr;
    },
  },
};
