import { GET_ITEM_STATUS_LIST, GET_ITEM_KEYWORD_LIST, ITEM_LIST_ACTION } from "./action";
import { ITEM_STATUS_LIST_MUTATION, ITEM_KEYWORD_LIST_MUTATION } from "./mutation";

import { CATEGORY_ACTION } from "../category/action";
import ApiService from "@/services/ApiService";
import apiPath from "@/services/apiPath";

export default {
  state: {
    statusList: [],
    keywordList: [],
  },
  actions: {
    async [GET_ITEM_STATUS_LIST](context) {
      //품목관리 상태
      if (context.state.statusList.length > 0) return; // 한번만 호출.
      //const result = await ApiService.shared.getData(apiPath.ITEM_STATUS_LIST); //API가 없어서 front에서 하드코딩함
      const result = {
        code: "",
        data: [
          { id: "", code: "", desc: "전체" },
          { id: "R", code: "R", desc: "대기" },
          { id: "C", code: "C", desc: "사용중" },
          { id: "S", code: "S", desc: "사용중지" },
          { id: "J", code: "J", desc: "사용불가" },
        ],
        text: "",
      };
      const { code, data, text } = result;
      this.commit({
        type: ITEM_STATUS_LIST_MUTATION,
        list: data || [],
      });
    },
    async [GET_ITEM_KEYWORD_LIST](context) {
      //품목관리 검색어
      if (context.state.keywordList.length > 0) return; // 한번만 호출.
      //const result = await ApiService.shared.getData(apiPath.ITEM_KEYWORD_LIST); //API가 없어서 front에서 하드코딩함
      const result = {
        code: "",
        data: [
          { id: "itemName", code: "itemName", desc: "품목명" },
          { id: "displayCode", code: "displayCode", desc: "품목코드" },
          { id: "manufacturer", code: "manufacturer", desc: "제조사" },
          { id: "standard", code: "standard", desc: "규격" },
        ],
        text: "",
      };
      const { code, data, text } = result;
      this.commit({
        type: ITEM_KEYWORD_LIST_MUTATION,
        list: data || [],
      });
    },

    async [ITEM_LIST_ACTION](context) {
      await context.dispatch(GET_ITEM_STATUS_LIST);
      await context.dispatch(GET_ITEM_KEYWORD_LIST);

      await context.dispatch(CATEGORY_ACTION.GET_LIST_0);
    },
  },
  mutations: {
    [ITEM_STATUS_LIST_MUTATION](state, payload) {
      //품목관리 상태
      const { list } = payload;
      state.statusList = Object.freeze(
        list.map((item, index) => Object.freeze({ ...item, index: index })),
      );
    },
    [ITEM_KEYWORD_LIST_MUTATION](state, payload) {
      //품목관리 검색어
      const { list } = payload;
      state.keywordList = Object.freeze(
        list.map((item, index) => Object.freeze({ ...item, index: index })),
      );
    },
  },
};
